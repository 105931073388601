import React from 'react';

import { Dictionary } from '../../../types';
import { AppIconDefinition } from './AppIcon.types';

const TAG_WHITE_LIST = ['circle', 'path', 'g', 'rect'];

const toCamelCase = (keyName: string) =>
    keyName
        .split('-')
        .map((item, index) => (index > 0 ? `${item.charAt(0).toUpperCase()}${item.slice(1)}` : item))
        .join('');

const toReactProps = (properties: Dictionary<string>) =>
    Object.keys(properties).reduce((props, key) => {
        if (key.indexOf('-') > 0) props[toCamelCase(key)] = properties[key];
        else props[key] = properties[key];

        return props;
    }, {} as Dictionary<string>);

export const transformToReactElement = ({ type, tagName, properties, children }: AppIconDefinition): React.ReactNode =>
    type === 'element' && TAG_WHITE_LIST.some((tag) => tag === tagName)
        ? React.createElement(
              tagName,
              toReactProps(properties),
              children.length
                  ? children.map((el, index) => {
                        el.properties.key = `${el.tagName}-${index}`;
                        return transformToReactElement(el);
                    })
                  : null
          )
        : null;
