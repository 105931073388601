/* istanbul ignore file */

export function getTitleForText(text: string, limit: number): string | undefined {
    if (text.length > limit) {
        return text;
    }
    return undefined;
}

export function getRelevantText(text: string, limit: number): string {
    if (text.length > limit) {
        return `${text.slice(0, limit)} ...`;
    }
    return text;
}

export { default } from './UserCard';
