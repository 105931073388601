import React from 'react';

import { Dialog } from '../Dialog/Dialog';
import { toClasses } from '../../utils';
import { DialogSize } from '../Dialog/Dialog.types';
import { Translate } from '../Translate/Translate';

interface Props {
    isOpened: boolean;
    onClose?: () => void;
    onConfirm: () => void;
    confirmationMessage: string | React.ReactNode;
    confirmButtonMessage?: string;
    cancelButtonMessage?: string;
    title?: string;
    className?: string;
}

export const ConfirmationDialog = ({
    isOpened,
    title,
    onConfirm,
    onClose,
    confirmationMessage,
    confirmButtonMessage,
    cancelButtonMessage,
    className,
}: Props) => (
    <Dialog
        isOpened={isOpened}
        onClose={onClose}
        className={toClasses('confirmation-dialog', !title && 'no-title', className)}
        title={title}
        size={DialogSize.Small}
    >
        <div className="confirmation-message">{confirmationMessage}</div>
        <div className="confirmation-footer">
            <button className="cancel-button" onClick={onClose}>
                {cancelButtonMessage || <Translate id="common.cancel" />}
            </button>
            <button className="confirm-button" onClick={onConfirm}>
                {confirmButtonMessage || <Translate id="common.confirm" />}
            </button>
        </div>
    </Dialog>
);
