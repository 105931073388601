import Dropdown from '../../Dropdown';
import React, { useCallback, useState } from 'react';
import { IdpSetting } from '../../../types';
import { Translate } from '../../Translate/Translate';
import Button from '../../Button';
import { DropdownDataItem } from '../../Dropdown/Dropdown';

interface Props {
    items: DropdownDataItem<IdpSetting>[];
    onSelect: (id: string) => void;
    username: string;
}

const generateIdpLSKey = (username: string) => `${username}-idp-last-selected`;

const getIdpFromLs = (items: DropdownDataItem<IdpSetting>[], username: string) => {
    const idpId = window.localStorage.getItem(generateIdpLSKey(username));
    const storageItem = items.find((i) => i.id === idpId);
    return storageItem || items[0];
};

export const IdpSelect = ({ items, onSelect, username }: Props) => {
    const [selected, setSelected] = useState<DropdownDataItem<IdpSetting>>(getIdpFromLs(items, username));
    const handleChange = useCallback((val: IdpSetting, id?: string) => setSelected(items.find((i) => i.id === id)!), [
        items,
    ]);
    const handleSubmit = useCallback(() => {
        window.localStorage.setItem(generateIdpLSKey(username), selected.id);
        onSelect(selected.id);
    }, [selected, onSelect, username]);

    return (
        <div className={'idp-select-container'}>
            <span className={'idp-title'}>
                <Translate id={'pleaseChooseYourIdentityProvider'} />
            </span>
            <Dropdown selectedId={selected.id} className={'idp-select'} items={items} onSelect={handleChange}>
                <span>{selected.label}</span>
            </Dropdown>
            <Button onClick={handleSubmit} color={'brand'} isRounded>
                <Translate id={'common.submit'} />
            </Button>
        </div>
    );
};
