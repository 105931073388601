export const LoginErrorCodes = {
    MULTIPLE_ERRORS: 'MULTIPLE_ERRORS',
};
export const LOGIN_CACHED_TENANTS_LS_KEY = 'tenants_';

export enum AppURLKeysEnum {
    RoomFinder = 'rf',
    SpaceManagement = 'sm',
    GO = 'go',
    Studio = 'st',
}

export const LOGIN_QUERY_APP_URL = 'app_url';
export const LOGIN_QUERY_APP_KEY = 'app_key';

export const API_PREFIX = '/rest';
export enum ApiVersionsEnum {
    latest = 'latest',
    v1 = 'v1',
    v2 = 'v2',
    default = '',
}
