import * as React from 'react';
import { AppAlert } from './AppAlert';
import { toClasses } from '../../utils';

interface Props {
    lifeTime?: number;
    bsStyle: string;
    children: React.ReactNode;
    onDismiss?: () => void;
    className?: string;
    showCloseButton?: boolean;
}
interface State {
    isReady: boolean;
}

class AlertCmp extends React.Component<Props, State> {
    public state = {
        isReady: false,
    };
    // @ts-ignore
    private timeoutId: number;
    public componentDidMount() {
        // make Alert ready
        // ready state means that its opacity changing from 0 to 1
        // with CSS-transitions it does fadein animation
        this.timeoutId = window.setTimeout(
            () =>
                this.setState(
                    {
                        ...this.state,
                        isReady: true,
                    },
                    () => this.setupLifeTimeDismiss()
                ),
            // eslint-disable-next-line no-magic-numbers
            100
        );
    }
    public componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }
    public render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
        const { lifeTime, className, ...props } = this.props;
        return (
            <AppAlert
                {...props}
                className={toClasses(`alert-${props.bsStyle}`, this.state.isReady && 'alert-ready', className)}
                onDismiss={() => this.onDismiss()}
            >
                {this.props.children}
            </AppAlert>
        );
    }

    private setupLifeTimeDismiss() {
        if (this.props.lifeTime) {
            this.timeoutId = window.setTimeout(() => this.onLifeTimeDismiss(), this.props.lifeTime);
        }
    }
    private onLifeTimeDismiss() {
        this.setState(
            {
                ...this.state,
                isReady: false,
            },
            () => this.onDismiss()
        );
    }

    private onDismiss() {
        if (this.props.onDismiss) {
            this.props.onDismiss.call(this);
        }
    }
}

export default AlertCmp;
