import React, { useMemo, useCallback } from 'react';
import { isFunction } from 'lodash-es';

import { toClasses } from '../../../../ui-toolkit/utils';
import { LookupOptionComponent, LookupOptionItem } from '../Lookup.types';

interface Props<V, E> {
    isActive: boolean;
    option: LookupOptionItem<V>;
    onClick: (value: V) => void;
    component?: LookupOptionComponent<V, E>;
    extra?: E;
}

export const LookupOption = <V, E extends object>({
    isActive,
    option,
    extra = {} as E,
    component: Component,
    onClick,
}: Props<V, E>) => {
    const handleClick = useCallback(() => onClick(option.value), [option, onClick]);

    const classes = useMemo(() => toClasses('lookup-option', isActive && 'is-active'), [isActive]);

    if (isFunction(Component)) {
        return (
            <li className={classes} onClick={handleClick}>
                <Component {...option} {...extra} onSelect={handleClick} />
            </li>
        );
    }

    return (
        <li className={classes} onClick={handleClick}>
            <span className="lookup-option-label">{option.label}</span>
        </li>
    );
};
