import { isString } from 'lodash-es';

export interface ImageResponse {
    data: ArrayBuffer;
    headers: Headers;
    status: number;
}

export const imgToBase64 = (data: string | ArrayBuffer) =>
    isString(data) ? new Buffer(data, 'binary').toString('base64') : new Buffer(data).toString('base64');
export const base64ToImg = (data: string) => Uint8Array.from(atob(data), (c) => c.charCodeAt(0));

export const setImageSrcPrefBase64 = (srcBase64: string, mime = 'image/png') => `data:${mime};base64, ${srcBase64}`;
export const unsetImageSrcPrefBase64 = (srcBase64: string, mime = 'image/png') =>
    srcBase64.replace(`data:${mime};base64, `, '');

export const imgToBase64WithPref = (data: string | ArrayBuffer, mime = 'image/png') =>
    setImageSrcPrefBase64(imgToBase64(data), mime);
export const base64WithPrefToImg = (srcBase64: string, mime = 'image/png') =>
    base64ToImg(unsetImageSrcPrefBase64(srcBase64, mime));

export const blobToBase64 = (data: Blob) => {
    const reader = new FileReader();
    return new Promise((res) => {
        reader.readAsDataURL(data);
        reader.onloadend = () => res(reader.result as string);
    }) as Promise<string>;
};

export const getImageAsBase64 = (() => {
    const memoImages: { [key: string]: string } = {};
    return (getImage: (url: string) => Promise<ImageResponse>, url: string): Promise<string> => {
        if (!memoImages[url]) {
            return getImage(url).then(({ data, headers }) => {
                // @ts-ignore
                const resultBase64String = imgToBase64WithPref(data, headers['content-type']);
                memoImages[url] = resultBase64String;
                return resultBase64String;
            });
        }
        return Promise.resolve(memoImages[url]);
    };
})();

/**
 * Converts a given string to a hash value using the classic Jenkins hash algorithm
 * @example stringToHash('hello world') => 1794106052
 */
export const stringToHash = (raw: string) => {
    const bitCount = 5;
    return raw.split('').reduce((acc, val) => ((acc << bitCount) - acc + val.charCodeAt(0)) | 0, 0);
};
