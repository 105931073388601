import { API_PREFIX, ApiVersionsEnum } from 'ui-toolkit/constants/login.ts';

export interface UrlProps {
    [key: string]: string | number;
}

type BaseObject = {
    [key: string]: string;
};

type ExecutableObject<T extends BaseObject> = {
    [K in keyof T]: (
        props?: UrlProps | null,
        apiVersion?: ApiVersionsEnum
    ) => string;
};

export const setUrlVersionAndPrefix = (
    url: string,
    apiVersion: ApiVersionsEnum = ApiVersionsEnum.default
) =>
    apiVersion === ApiVersionsEnum.default
        ? `${API_PREFIX}${url}`
        : `${API_PREFIX}/${apiVersion}${url}`;

export const interpURLs = <T extends BaseObject>(
    baseObj: T
): ExecutableObject<T> => {
    const executableObj: ExecutableObject<T> = {} as ExecutableObject<T>;

    Object.keys(baseObj).forEach(key => {
        executableObj[key as keyof T] = (
            props?: UrlProps | null,
            apiVersion: ApiVersionsEnum = ApiVersionsEnum.default
        ) =>
            setUrlVersionAndPrefix(
                props
                    ? Object.keys(props).reduce(
                          (urlString: string, currentKey: string) =>
                              urlString.replace(
                                  `:${currentKey}`,
                                  encodeURIComponent(props[currentKey])
                              ),
                          baseObj[key]
                      )
                    : baseObj[key],
                apiVersion
            );
    });

    return executableObj;
};
