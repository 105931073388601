import React from 'react';

export const useOnClickOutside = function <T extends HTMLElement>(
    ref: React.RefObject<T>,
    cb: (target?: HTMLElement) => void,
    active = true,
    skipTargets: string[] = [] // class selectors
) {
    const handler = React.useCallback(
        ({ target }: MouseEvent) => {
            for (let i = 0; i < skipTargets.length; i++) {
                if ((target as HTMLElement).closest(skipTargets[i])) {
                    return;
                }
            }
            if (ref && ref.current && !ref.current.contains(target as Node)) {
                cb(target as HTMLElement);
            }
        },
        [ref, cb, skipTargets]
    );

    React.useEffect(() => {
        if (active) {
            document.removeEventListener('click', handler, { capture: true });
            document.addEventListener('click', handler, { capture: true });
        }
        return () => document.removeEventListener('click', handler);
    }, [ref, active, handler]);
};
