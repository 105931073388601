import React from 'react';
import { TranslateFunction } from '../../../types/translations';

interface Props {
    email: string;
    translate: TranslateFunction;
}

const prepareEmail = (email: string) => email.toUpperCase().split('@')[0];

const SecondStep = ({ email, translate }: Props) => (
    <div className={'form-group'}>
        <p>{translate('forgot.remind.endMessage', { email: prepareEmail(email) })}</p>
    </div>
);

export default SecondStep;
