import React, { useCallback, useMemo } from 'react';

import Button from '../../../components/Button';
import { Lookup } from '../../Lookup/Lookup';
import { LookupOptionItem } from '../../Lookup/Lookup.types';
import { Translate } from '../../Translate/Translate';
import { SSOSettings } from '../../../types';

interface Props {
    storedTenants: string[];
    onBack: () => void;
    onNextStep: (tenantId: string, settings: SSOSettings) => void;
    fetchSettings: (username: string, organization?: string) => Promise<SSOSettings>;
    username: string;
}

const LoginThirdStep = ({ storedTenants, onBack, onNextStep, fetchSettings, username }: Props) => {
    const [tenant, setTenant] = React.useState('');

    const handleBack = React.useCallback(() => onBack(), []);

    const handleTenantPick = React.useCallback((selected: string) => selected && setTenant(selected), []);

    const handleTenantChange = React.useCallback((value: string) => setTenant(value), []);

    const handleNextStep = React.useCallback(() => {
        fetchSettings(username, tenant).then((settings) => {
            onNextStep(tenant, settings);
        });
    }, [tenant, username]);

    const tenantsAsOptions: LookupOptionItem<string>[] = useMemo(
        () => storedTenants.map((i): LookupOptionItem<string> => ({ id: i, label: i, search: i, value: i })),
        [storedTenants]
    );

    const handleEnterKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            e.stopPropagation();
            handleNextStep();
        },
        [handleNextStep]
    );

    const selectedOption: LookupOptionItem<string> = useMemo(
        (): LookupOptionItem<string> => ({
            id: tenant,
            label: tenant,
            search: tenant,
            value: tenant,
        }),
        [tenant]
    );

    return (
        <>
            <input type="hidden" name="organization" value={tenant} />
            <Lookup
                selected={selectedOption}
                items={tenantsAsOptions}
                onChange={handleTenantPick}
                onQueryChange={handleTenantChange}
                onEnterKeyPress={handleEnterKeyPress}
            >
                {tenant}
            </Lookup>
            <div className="login-controls">
                <Button className="login-back-btn" isRounded={true} isOutline={true} onClick={handleBack}>
                    <Translate id={'common.back'} />
                </Button>
                <Button
                    className="login-proceed-btn"
                    color="brand"
                    type="button"
                    isRounded={true}
                    onClick={handleNextStep}
                    disabled={!tenant}
                >
                    <Translate id={'common.next'} />
                </Button>
            </div>
        </>
    );
};

export default LoginThirdStep;
