import React from 'react';

import Button from '../../../components/Button';
import { TranslateFunction } from '../../../types/translations';
import { Translate } from '../../Translate/Translate';
import { LoginFormTemplate } from '../LoginFormTemplate/LoginFormTemplate';

interface Props {
    logo: string;
    translate: TranslateFunction;
    onTryAgain: () => void;
}

export const SsoFailed = ({ translate, onTryAgain, logo }: Props) => {
    const handleTryAgain = React.useCallback(() => onTryAgain(), [onTryAgain]);

    return (
        <LoginFormTemplate
            logo={logo}
            className={'no-sso-container'}
            title={translate('auth.noSSOConnection') as string}
        >
            <div className={'no-sso'}>
                <div className={'login-controls'}>
                    <Button className={'login-back-btn'} fullWidth isOutline isRounded onClick={handleTryAgain}>
                        <Translate id={'auth.tryAgain'} />
                    </Button>
                </div>
            </div>
        </LoginFormTemplate>
    );
};
