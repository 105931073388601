import React from 'react';

import { ENTER_KEY_CODE } from '../../../constants';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { TranslateFunction } from '../../../types/translations';
import { Translate } from '../../Translate/Translate';

interface Props {
    hasLocalUsers: boolean;
    username: string;
    isActive: boolean;
    showBackBtn?: boolean;
    translate: TranslateFunction;
    // This method should load SSO settings for specific user
    onNextStep: (login: string) => Promise<boolean>;
    onUseStoredAccounts: () => void;
    onForgotPassword: () => void;
}

const LoginSecondStep = ({
    username,
    isActive,
    hasLocalUsers,
    translate,
    onNextStep,
    onUseStoredAccounts,
    onForgotPassword,
    showBackBtn = true,
}: Props) => {
    const [login, setLogin] = React.useState(username);
    const [isLoading, setLoading] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleChange = React.useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => setLogin(target.value + ''),
        []
    );

    const goToTheNextStep = React.useCallback(() => {
        setLoading(true);
        onNextStep(login)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [login]);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === ENTER_KEY_CODE) {
                // ensure to not submit form on this step
                event.preventDefault();
                goToTheNextStep();
            }
        },
        [login]
    );

    const handleGoBack = React.useCallback(() => onUseStoredAccounts(), []);

    const handleForgotPassword = React.useCallback(() => onForgotPassword(), []);

    React.useEffect(() => {
        if (username !== login) {
            setLogin(username);
        }
    }, [username]);

    React.useEffect(() => {
        /* istanbul ignore else */
        if (inputRef.current && isActive) {
            inputRef.current.focus();
        }
    }, [isActive]);

    return (
        <>
            <Input
                type="text"
                name="login"
                className="login-input"
                ref={inputRef}
                onKeyDown={handleKeyDown}
                value={login}
                autoComplete="login"
                onChange={handleChange}
                placeholder={translate('auth.usernameOrEmail') as string}
            />
            <div className={`login-controls ${!hasLocalUsers ? 'single' : ''}`}>
                {hasLocalUsers && showBackBtn && (
                    <Button isOutline={true} className={'login-back-btn'} isRounded={true} onClick={handleGoBack}>
                        <Translate id={'common.back'} />
                    </Button>
                )}
                <Button
                    className="login-proceed-btn"
                    color="brand"
                    isRounded={true}
                    isLoading={isLoading}
                    disabled={!login.length}
                    onClick={goToTheNextStep}
                >
                    <Translate id={'common.next'} />
                </Button>
            </div>
            <Button className="login-link" color={'link'} onClick={handleForgotPassword}>
                <Translate id={'forgot.nav.main'} />
            </Button>
        </>
    );
};

export default LoginSecondStep;
