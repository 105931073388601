export const MINUTE_MS = 60000;
export const HOUR_MS = 3600000;

export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const MILISECONDS_IN_SECOND = 1000;
export const MAX_SINGLE_DIGIT_NUMBER = 9;

export const TIME_THRESHOLDS = {
    ss: 0, // a few seconds to seconds
    s: 60, // seconds to minute
    m: 60, // minutes to hour
    h: 24, // hours to day
    d: 30, // days to month/week
    M: 12, // months to year
};

export const DEFAULT_DEBOUNCE_MS = 300;
