import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { Location } from 'history';
import { SSOSettings } from '../../ui-toolkit/types';
import { get } from 'lodash-es';
import { RedirectAppData } from '../store/login.store.ts';
import {
    LOGIN_QUERY_APP_KEY,
    LOGIN_QUERY_APP_URL,
} from 'ui-toolkit/constants/login.ts';

export const getForgotPasswordHandler =
    (navigate: NavigateFunction, tenantId?: string) => () =>
        navigate('/remind', {
            state: { tenantId },
        });

export function loginViaSSO(
    { state }: Location,
    tenantId: string | undefined,
    redirectData: RedirectAppData
) {
    return (
        username: string,
        settings?: SSOSettings,
        organization?: string,
        idpId?: string
    ) => {
        let status = false;
        if (
            get(settings, 'samlEnabled', false) &&
            !get(state, 'ssoFailed', false) &&
            !get(settings, 'isOrganizationRequired', false)
        ) {
            status = true;
            const originHash = btoa(document.location.origin);
            const apiUrl = get(settings, 'apiUrl', '');
            const params = [
                'redirectUrl=' + originHash,
                tenantId ? 'customLogin=true' : '',
                `${LOGIN_QUERY_APP_KEY}=${redirectData.appKey}`,
                `${LOGIN_QUERY_APP_URL}=${encodeURIComponent(redirectData.appURL)}`,
            ];
            if (organization) {
                params.push('organization=' + encodeURIComponent(organization));
            }
            const samlURL = `${apiUrl}/saml/init/${encodeURIComponent(username)}${idpId ? '/samlid/' + idpId : ''}`;
            window.location.href = samlURL + '?' + params.join('&');
        }
        return Promise.resolve(status);
    };
}

export const loginWithCobundu = (
    navigate: NavigateFunction,
    tenantId?: string
) => {
    tenantId =
        tenantId && tenantId?.endsWith('.')
            ? tenantId.substring(0, tenantId.length - 1)
            : tenantId;
    return () =>
        navigate((tenantId ? `/login/${tenantId}` : '/') + location.search, {
            state: { ssoFailed: true },
        });
};
