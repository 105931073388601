/* istanbul ignore file */

import { isUndefined } from 'lodash-es';

export function moveDown(last: number) {
    return (current?: number) => {
        if (isUndefined(current)) {
            return 0;
        }
        const next = current + 1;
        return next >= last ? 0 : next;
    };
}

export function moveUp(last: number) {
    return (current?: number) => {
        if (isUndefined(current)) {
            return 0;
        }
        const previous = current - 1;
        return previous < 0 ? last : previous;
    };
}

export { default } from './Dropdown';
