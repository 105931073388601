import React from 'react';

import { toClasses } from '../../utils';

interface Props extends React.HTMLProps<HTMLDivElement> {
    fullScreen?: boolean;
    enableBackground?: boolean;
    transparentBackground?: boolean;
    fullContainerSize?: boolean;
}

const Spinner = ({
    fullScreen = false,
    enableBackground = false,
    transparentBackground = false,
    fullContainerSize = false,
    className,
    ...props
}: Props) => {
    const stubClasses = React.useMemo(
        () =>
            toClasses(
                'spinner',
                className,
                enableBackground && 'spinner-background',
                transparentBackground && 'spinner-background-transparent',
                fullScreen && 'spinner-full-screen',
                fullContainerSize && 'spinner-full-container-size'
            ),
        [className, enableBackground, fullScreen]
    );

    return (
        <div className={stubClasses} {...props}>
            <div className="spinner-circle" />
        </div>
    );
};

export default Spinner;
