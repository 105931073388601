import React, { useEffect, useMemo } from 'react';

import { toClasses, generateId } from '../../../utils';
import { transformToReactElement } from './AppIcon.utils';
import definitions from '../icon-definitions.json';
import { Props } from '../Icon';
import { AppIconDefinition } from './AppIcon.types';

export const APP_ICON_SUFFIXES = ['spacewell', 'sw'];

export const AppIcon = ({ name, className, onClick, isDisabled }: Props) => {
    const classes = useMemo(() => toClasses('icon', `icon-${name}`, isDisabled && 'disabled', className), [
        className,
        name,
        isDisabled,
    ]);

    const icon = useMemo(() => {
        const definition = definitions.find(({ name: definitionName }) => definitionName === name);
        if (!definition) return null;

        return (
            <svg
                xmlns={definition.content.properties.xmlns}
                viewBox={definition.content.properties.viewBox}
                fill={'currentColor'} // @ts-ignore
                className={toClasses(classes, definition.content.properties.class)}
                onClick={onClick}
            >
                {(definition.content.children as AppIconDefinition[]).map((child) => (
                    <React.Fragment key={generateId()}>{transformToReactElement(child)}</React.Fragment>
                ))}
            </svg>
        );
    }, [name, classes, onClick]);

    useEffect(() => {
        if (!icon) console.warn(`There is no App icon with name: ${name}`);
    }, [icon, name]);

    return icon;
};
