import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-router';

import ChangePasswordForm from '../../../ui-toolkit/components/Login/ChangePassword/ChangePasswordForm.tsx';
import { logoPath } from '../../constants/localization.constants.ts';
import { getPasswordRules } from '../../api/login.api.ts';
import { useLoginStore } from '../../store/login.store.ts';
import { useLoginModel } from '../Login/useLoginModel.ts';
import { ErrorBoundary } from 'ui-toolkit/components/ErrorBoundary/ErrorBoundary.tsx';
import { AlertContext } from 'ui-toolkit/context/Alert/AlertContext.tsx';

export const ChangePassword = () => {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const { onChangePassword, isLoading } = useLoginModel();
    const { onErrorAlert } = useContext(AlertContext);

    const {
        setPasswordExpired,
        isCaptchaRequired,
        isOrganizationRequired,
        userData,
    } = useLoginStore(state => ({
        isCaptchaRequired: state.isCaptchaRequired,
        isOrganizationRequired: state.isOrganizationRequired,
        userData: state.passwordExpired,
        setPasswordExpired: state.setPasswordExpired,
    }));

    const getRules = useCallback(() => {
        if (userData) {
            return getPasswordRules('', userData.username || '');
        }
        return Promise.reject('No username');
    }, [userData]);

    return (
        <ErrorBoundary>
            <ChangePasswordForm
                userData={userData}
                setPasswordIsNotRequired={() => setPasswordExpired(null)}
                isCaptchaRequired={isCaptchaRequired}
                changePassword={onChangePassword}
                isOrganizationRequired={isOrganizationRequired}
                translate={t}
                logo={logoPath}
                getRules={getRules}
                className="rhye-auth"
                title="auth.rhye.loginTitle" // @ts-ignore
                state={navigation.state}
                setError={onErrorAlert}
                withErrorAlert
                isLoading={isLoading}
            />
        </ErrorBoundary>
    );
};
