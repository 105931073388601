import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from 'react-router-dom';

import { Login } from '../components/Login/Login.tsx';

import { RemindPassword } from '../components/Remind/RemindPassword.tsx';
import { LoginSSO } from '../components/Login/LoginSSO.tsx';
import { SsoFailedPage } from '../components/Login/SsoFailedPage.tsx';
import { ChangePassword } from '../components/ChangePassword/ChangePassword.tsx';
import React, { Suspense } from 'react';
import Spinner from 'ui-toolkit/components/Spinner';
import { noop } from 'lodash-es';
import { SuccessSso } from '../components/Login/SuccessSso.tsx';
import { NoSsoPage } from '../components/Login/NoSSOPage.tsx';

const ResetPassword = React.lazy(
    () => import('../components/ResetPassword/ResetPassword.tsx')
);

const router = createBrowserRouter([
    {
        path: '/change-password',
        element: <ChangePassword />,
    },
    {
        path: '/remind',
        element: <RemindPassword />,
    },
    {
        path: '/reset',
        element: <ResetPassword />,
    },
    {
        path: '/sso',
        element: <LoginSSO />,
    },
    {
        path: '/no-sso',
        element: <NoSsoPage />,
    },
    {
        path: '/reset/:code',
        element: <ResetPassword />,
    },
    {
        //TODO app prefix needed to support link from e-mail
        path: '/app/reset/:code',
        element: <ResetPassword />,
    },
    {
        path: '/login/:tenantId',
        element: <Login onError={noop} />,
    },
    {
        path: '/success-sso',
        element: <SuccessSso />,
    },
    {
        path: '/error-sso',
        element: <SsoFailedPage />,
    },
    {
        path: '/error-sso/:username',
        element: <SsoFailedPage />,
    },
    {
        path: '/',
        element: <Login onError={noop} />,
    },
    {
        path: '*',
        element: <Navigate to={`/${location.search}`} />,
    },
]);

export const LoginRoutes = () => (
    <Suspense fallback={<Spinner />}>
        <RouterProvider router={router} />
    </Suspense>
);
