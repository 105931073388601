import { useContext, useEffect, useState } from 'react';

import { fetchTranslations } from './locatization/locatization.utils.ts';
import i18n from './locatization/i18n.ts';
import { AlertContext } from 'ui-toolkit/context/Alert/AlertContext.tsx';
import { setAppTitle, setRedirectAppData } from './store/login.store.ts';
import {
    AppURLKeysEnum,
    LOGIN_QUERY_APP_KEY,
    LOGIN_QUERY_APP_URL,
} from 'ui-toolkit/constants/login.ts';
import {
    AppTitleKeyMap,
    DEFAULT_LOGIN_URL,
} from './constants/login.constants.ts';

export const useAppModel = () => {
    const { alerts, onRemoveAlert } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
        fetchTranslations(['en'])
            .finally(() => {
                i18n.changeLanguage('en');
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const key = queryParams.get(LOGIN_QUERY_APP_KEY) as AppURLKeysEnum;
        const url = queryParams.get(LOGIN_QUERY_APP_URL);

        setRedirectAppData({
            appKey: key || AppURLKeysEnum.GO,
            appURL: url ? decodeURIComponent(url) : DEFAULT_LOGIN_URL,
        });

        setAppTitle(AppTitleKeyMap[key || AppURLKeysEnum.GO]);
    }, []);

    return { alerts, onRemoveAlert, isLoading };
};
