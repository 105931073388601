import React from 'react';

interface Props {
    onClick: () => void;
    children: React.ReactNode;
}
const SnowmanMenuItem = ({ children, onClick }: Props) => {
    const handleClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onClick();
    }, []);

    return (
        <a href="#" className="snowman-menu__action" role="button" onClick={handleClick}>
            {children}
        </a>
    );
};

export default SnowmanMenuItem;
