import React, { ReactNode } from 'react';

import { LastLoginData, LocalUser } from '../../../types';
import { ConfirmationDialog } from '../../ConfirmationDialog/ConfirmationDialog';
import UserCard from '../UserCard';
import { Translate } from '../../Translate/Translate';
import { TranslateFunction } from '../../../types/translations';

interface Props {
    previousUsers: LocalUser[];
    translate: TranslateFunction;
    // This method should load SSO settings for specific user
    onNextStep: (data: LastLoginData) => Promise<boolean>;
    onUseAnotherAccount: () => void;
    onRemoveUser: (userId: string) => void;
    getAvatar: (user: LocalUser) => ReactNode;
}

const LoginFirstStep = ({
    previousUsers,
    translate,
    onNextStep,
    onRemoveUser,
    onUseAnotherAccount,
    getAvatar,
}: Props) => {
    const [userToRemove, removeUser] = React.useState('');

    const handleUserClick = React.useCallback(
        (data: LastLoginData) => {
            onNextStep(data);
        },
        [onNextStep]
    );

    const handleDeleteUserConfirmation = React.useCallback(() => {
        onRemoveUser(userToRemove);
        removeUser('');
    }, [userToRemove]);

    const handleDeleteUserRejection = React.useCallback(() => {
        removeUser('');
    }, []);

    const removeConfirmDialogMessage = React.useMemo(
        () => (
            <>
                {translate('auth.removeAccountMessage', { USERNAME: userToRemove })}
                <br />
                <Translate id={'auth.removeAccountProceed'} />
            </>
        ),
        [userToRemove, translate]
    );

    return (
        <>
            {previousUsers.map((data) => (
                <UserCard
                    data={data}
                    key={data.login}
                    actions={[
                        { label: 'auth.removeThisAccount', handler: () => removeUser(data.lastLoginData.userId) },
                    ]}
                    onUserClick={handleUserClick}
                    getAvatar={getAvatar}
                />
            ))}
            <ConfirmationDialog
                isOpened={!!userToRemove}
                onClose={handleDeleteUserRejection}
                onConfirm={handleDeleteUserConfirmation}
                title={translate('auth.removeAccountTitle') as string}
                confirmButtonMessage={translate('common.yes') as string}
                cancelButtonMessage={translate('common.no') as string}
                confirmationMessage={removeConfirmDialogMessage}
            />
            <div className="user-card user-card__action add-new" onClick={onUseAnotherAccount}>
                <div className="user-card__body">
                    <div className="user-card__data">
                        <p className="user-card__name">
                            <Translate id={'auth.useAnotherAccount'} />
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginFirstStep;
