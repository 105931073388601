import React from 'react';
import zxcvbn from 'zxcvbn';

import { toClasses } from '../../utils';
import { TranslateFunction } from '../../types/translations';
import { Translate } from '../Translate/Translate';

interface Props {
    name: string;
    value: string;
    translate: TranslateFunction;
    required?: boolean;
    strength?: boolean;
    selfStrength?: boolean;
    userInputs?: string[];
    className?: string;
    placeholder?: string;
    label?: React.ReactNode;
    inputClasses?: string;
    color?: [string, string, string, string, string];
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
}

const strengthLevel = ['veryWeak', 'weak', 'notGood', 'good', 'perfect'];

const PasswordField = ({
    name,
    label,
    placeholder,
    onChange,
    onBlur = () => false,
    className = '',
    value,
    strength = false,
    color = ['has-error', 'has-error', 'has-warning', 'has-warning', 'has-success'],
    userInputs = [],
    required = false,
    selfStrength = false,
    children,
    inputClasses = '',
}: Props) => {
    const { score } = React.useMemo(() => zxcvbn(value, userInputs), [value, ...userInputs]);
    return (
        <div className={toClasses('form-group', className, selfStrength && color[score])}>
            {label && <label className={'control-label'}>{label}</label>}
            <input
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                required={required}
                className={toClasses('form-control', inputClasses)}
                type="password"
                onBlur={onBlur}
            />
            {strength && (
                <span className="help-block">
                    <Translate id={`auth.password.${strengthLevel[score]}`} />
                </span>
            )}
            {children}
        </div>
    );
};

PasswordField.defaultProps = {
    required: false,
    strength: false,
    selfStrength: false,
    onBlur: () => false,
    userInputs: [],
    className: '',
    color: ['has-error', 'has-error', 'has-warning', 'has-warning', 'has-success'],
};

export default PasswordField;
