import React from 'react';
import ReactDOM from 'react-dom';
import { generateId } from '../../../ui-toolkit/utils';
import { ErrorContainer } from './ErrorContainer/ErrorContainer';
import { Translate } from '../Translate/Translate';

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

interface ErrorAlert {
    id: string;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
    timer: NodeJS.Timer;
}

interface State {
    errors: ErrorAlert[];
}

const ERROR_ALERT_LIFETIME = 10000;
const containerId = 'error-container';

export class ErrorBoundary extends React.Component<Props, State> {
    state = {
        errors: [],
    };

    constructor(props: Props) {
        super(props);
        let errorContainer = document.body.querySelector(`#${containerId}`);
        if (!errorContainer) {
            errorContainer = document.createElement('div');
            errorContainer.id = containerId;
            document.body.appendChild(errorContainer);
        }
    }

    removeAlert(id: string) {
        this.setState({
            errors: this.state.errors.filter((er: ErrorAlert) => er.id !== id),
        });
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const id = generateId();
        this.setState({
            errors: [
                //@ts-ignore
                ...this.state.errors,
                //@ts-ignore
                { id, error, errorInfo, timer: setTimeout(() => this.removeAlert(id), ERROR_ALERT_LIFETIME) },
            ],
        });
    }

    render() {
        if (this.state.errors.length) {
            return ReactDOM.createPortal(
                <>
                    {this.state.errors.map((i: ErrorAlert) => (
                        <ErrorContainer onDismiss={() => this.removeAlert(i.id)} key={i.id}>
                            <Translate id={'common.clientErrorMessage'} />
                        </ErrorContainer>
                    ))}
                </>,
                document.getElementById(containerId)!
            );
        }
        return this.props.children;
    }
}
