import { flow, toLower } from 'lodash-es';

export const removeExtraSpaces = (str: string) => str.replace(/\s+/g, ' ').trim();
export const normalizeString = (str: string) => flow(toLower, removeExtraSpaces)(str);

export const includes = (baseStr: string, includedStr: string, normalize?: boolean) => {
    const base = normalize ? normalizeString(baseStr) : baseStr;
    const included = normalize ? normalizeString(includedStr) : includedStr;
    return base.includes(included);
};
