import {
    getForgotPasswordHandler,
    loginViaSSO,
} from '../../utils/login.utils.ts';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logoPath } from '../../constants/localization.constants';
import { useNavigate } from 'react-router';
import { IdpState } from '../../../ui-toolkit/components/Login/LoginForm/LoginForm';
import { IdpSelect } from '../../../ui-toolkit/components/Login/IdpSelect/IdpSelect';
import { LoginFormTemplate } from '../../../ui-toolkit/components/Login/LoginFormTemplate/LoginFormTemplate';
import LoginOnlySSO from '../../../ui-toolkit/components/Login/LoginSSO/OnlySSO.tsx';
import { IdpSetting, SSOSettings } from '../../../ui-toolkit/types';
import { getSsoSettings } from '../../api/login.api.ts';
import { ErrorBoundary } from 'ui-toolkit/components/ErrorBoundary/ErrorBoundary.tsx';
import { useLoginStore } from '../../store/login.store.ts';

export const LoginSSO = () => {
    const [activeIdpsState, setActiveIpdsState] = useState<IdpState | null>(
        null
    );
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const loginStore = useLoginStore(store => store);

    const handleForgetPassword = useMemo(
        () => getForgotPasswordHandler(navigate),
        [navigate]
    );

    const handleLogin = useCallback(
        (uName: string) => {
            setUsername(uName);
            return getSsoSettings(uName).then((settings: SSOSettings) => {
                if (settings.idpList.length > 1)
                    setActiveIpdsState({
                        idpItems: settings.idpList.map((i: IdpSetting) => ({
                            id: i.id,
                            label: i.name,
                            value: i,
                        })),
                        settings,
                        organization: '',
                    });
                else
                    loginViaSSO(
                        location,
                        undefined,
                        loginStore.redirectAppData
                    )(uName, {
                        samlEnabled: true,
                    } as SSOSettings);
            });
        },
        [location, loginStore.redirectAppData]
    );

    const handleSubmitIdp = useCallback(
        (id: string) => {
            if (activeIdpsState) {
                setIsLoading(true);
                loginViaSSO(location, undefined, loginStore.redirectAppData)(
                    username,
                    { samlEnabled: true } as SSOSettings,
                    undefined,
                    id
                ).finally(() => {
                    setActiveIpdsState(null);
                    setIsLoading(false);
                });
            }
        },
        [activeIdpsState, username, location, loginStore.redirectAppData]
    );

    if (activeIdpsState) {
        return (
            <LoginFormTemplate
                isLoading={isLoading}
                logo={logoPath}
                title={t(loginStore.appTitleKey)}
                subTitle={t('auth.subTitle')}>
                <IdpSelect
                    username={username}
                    items={activeIdpsState.idpItems}
                    onSelect={handleSubmitIdp}
                />
            </LoginFormTemplate>
        );
    }

    return (
        <ErrorBoundary>
            <LoginOnlySSO
                translate={t}
                logo={logoPath}
                className="gray-bg"
                onLogin={handleLogin}
                onForgotPassword={handleForgetPassword}
            />
        </ErrorBoundary>
    );
};
