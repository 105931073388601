import React, { ReactNode } from 'react';

import { LastLoginData, LocalUser } from '../../../types';
import { getRelevantText, getTitleForText } from './';
import UserCardActions from './Actions';

type Actions = React.ComponentProps<typeof UserCardActions>['actions'];

interface Props {
    data: LocalUser;
    actions?: Actions;
    onUserClick?: (data: LastLoginData) => void;
    getAvatar: (user: LocalUser) => ReactNode;
}

const MAX_NAME_LENGTH = 20;
const MAX_TENANT_LENGTH = 48;

const UserCard = ({ data, actions = [], onUserClick, getAvatar }: Props) => {
    const handleClick = React.useCallback(() => {
        if (onUserClick) {
            onUserClick(data.lastLoginData);
        }
    }, []);

    const nameTitle = React.useMemo(() => getTitleForText(data.name, MAX_NAME_LENGTH), [data.name]);

    const tenantTitle = React.useMemo(() => getTitleForText(data.tenant, MAX_TENANT_LENGTH), [data.name]);

    const tenantLabel = React.useMemo(() => getRelevantText(data.tenant, MAX_TENANT_LENGTH), [data.name]);

    const avatar = React.useMemo(() => getAvatar(data), [data]);

    return (
        <div className="user-card">
            <div className="user-card__body" onClick={handleClick}>
                {avatar}
                <div className="user-card__data">
                    <p className="user-card__name" title={nameTitle}>
                        {data.name}
                    </p>
                    <p className="user-card__tenant" title={tenantTitle}>
                        {tenantLabel}
                    </p>
                </div>
            </div>
            <UserCardActions {...{ actions }} />
        </div>
    );
};

export default UserCard;
