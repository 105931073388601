import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { noop, isNumber } from 'lodash-es';

import { LookupOption } from './LookupOption/LookupOption';
import SearchInput from '../SearchInput';
import { AppIcon } from '../Icon/AppIcon/AppIcon';
import { moveDown, moveUp } from '../../../ui-toolkit/components/Dropdown';
import { inCurrent, toClasses } from '../../../ui-toolkit/utils';
import { hasItemWithId, hasQuery, isSearchInputClearBtn } from './Lookup.utils';
import { LookupOptionItem, LookupOptionComponent } from './Lookup.types';
import { DEFAULT_DEBOUNCE_MS } from '../../constants/time';

interface Props<V, E> {
    items: LookupOptionItem<V>[];
    onChange?: (value: V) => void;
    onEnterKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    selected?: LookupOptionItem<V>;
    className?: string;
    directionTop?: boolean;
    component?: LookupOptionComponent<V, E>;
    noResultStub?: React.ReactNode | null;
    extra?: E;
    hideCaret?: boolean;
    lookupOptionsRef?: React.RefObject<HTMLUListElement> | null;
    onQueryChange?: (query: string) => void;
}

export const Lookup = <V, E extends object>({
    items,
    selected,
    extra,
    component,
    className,
    children,
    disabled,
    onChange = noop,
    onEnterKeyPress,
    onFocus = noop,
    onBlur = noop,
    hideCaret,
    lookupOptionsRef,
    directionTop,
    noResultStub,
    onQueryChange = noop,
}: Props<V, E>) => {
    const [isOpened, setOpened] = useState(false);
    const [query, setQuery] = useState('');
    const [options, setOptions] = useState(items);
    const [active, setActive] = useState(selected ? items.findIndex(hasItemWithId(selected)) : undefined);
    const lookupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const callback = window.setTimeout(
            () => setOptions(items.filter(hasQuery(query.toLowerCase()))),
            DEFAULT_DEBOUNCE_MS
        );
        return () => window.clearTimeout(callback);
    }, [items, query]);

    useEffect(() => {
        if (disabled) return;

        document.addEventListener('click', globalHandler);
        return () => document.removeEventListener('click', globalHandler);
    }, [disabled]);

    useEffect(() => {
        if (selected) setActive(items.findIndex(hasItemWithId(selected)));
    }, [selected, items]);

    useEffect(() => onQueryChange?.(query), [query]);

    const showOptions = useCallback(() => {
        if (disabled) return;
        setOpened(true);
    }, [disabled]);

    const hideOptions = useCallback(() => setOpened(false), []);

    const clearQuery = useCallback(() => setQuery(''), []);

    const handleEnterKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            onEnterKeyPress && onEnterKeyPress(e);
            if (isOpened && isNumber(active)) {
                /*For login form (tenant select screen) there may be no options*/
                onChange(options[active] && options[active].value);
                hideOptions();
            } else {
                showOptions();
            }
        },
        [active, isOpened, onChange, options, onEnterKeyPress]
    );

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            switch (e.key) {
                case 'Enter':
                    return handleEnterKeyPress(e);
                case 'Escape':
                    return hideOptions();
                case 'ArrowDown':
                    return setActive(moveDown(options.length));
                case 'ArrowUp':
                    return setActive(moveUp(options.length - 1));
            }
        },
        [options, handleEnterKeyPress]
    );

    const globalHandler = useCallback(
        ({ target }: MouseEvent) => {
            if (inCurrent(lookupRef.current, target as HTMLElement) || isSearchInputClearBtn(target as HTMLElement))
                return;
            return hideOptions();
        },
        [lookupRef.current]
    );

    const handleQueryChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => setQuery(target.value),
        []
    );

    const classes = useMemo(() => toClasses('lookup', disabled && 'is-disabled', className), [className, disabled]);

    return (
        <div ref={lookupRef} className={classes} onKeyDown={handleKeyDown} tabIndex={0}>
            <div className="lookup-trigger" onClick={showOptions}>
                {children}
                {!hideCaret && <AppIcon className="lookup-caret" name="carret-down" />}
            </div>
            {isOpened && (
                <div className="lookup-menu">
                    <SearchInput
                        onFocus={onFocus}
                        onBlur={onBlur}
                        autoFocus={true}
                        value={query}
                        onChange={handleQueryChange}
                        onClear={clearQuery}
                    />
                    {!!options.length || noResultStub ? (
                        <ul
                            className={toClasses('lookup-options', directionTop && 'top-direction')}
                            onClick={hideOptions}
                            ref={lookupOptionsRef}
                        >
                            {!noResultStub ? (
                                options.map((option, index) => (
                                    <LookupOption
                                        {...{ option, extra, component }}
                                        key={option.id}
                                        isActive={active === index}
                                        onClick={onChange}
                                    />
                                ))
                            ) : (
                                <li className={'lookup-option'}>{noResultStub}</li>
                            )}
                        </ul>
                    ) : null}
                </div>
            )}
        </div>
    );
};
