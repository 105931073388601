import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    getAllLastLogins,
    removeLastLogin,
    setLastLogin,
} from '../../api/login.api.ts';
import { LastLogin } from '../../type';
import { take } from 'lodash-es';
import { LocalUser } from 'ui-toolkit/types';
import { AppURLKeysEnum } from 'ui-toolkit/constants/login.ts';
import { useLoginStore } from '../../store/login.store.ts';
import Cookies from 'js-cookie';

export interface LastLoginsModel {
    lastLogins: LastLogin[];
    handleSetLastLogin: () => void;
    handleRemoveLastLogin: (userId: string) => void;
    isFetched: boolean;
}

/* TODO this is temporary fallback to save previous logins for user */
const mergeLastLoginsWithStorage = (
    data: LastLogin[],
    app: AppURLKeysEnum
): LastLogin[] => {
    const MAX_LAST_LOGINS = 4;
    const existingUserIds = data.map(i => i.userId);
    const cookieLastLogins =
        app === AppURLKeysEnum.Studio
            ? JSON.parse(Cookies.get('studioLastLogins') || '[]')
            : JSON.parse(Cookies.get('goLastLogins') || '[]');
    const storageItems: LastLogin[] = cookieLastLogins
        .map((i: LocalUser) => ({
            userId: i.login,
            userName: i.name,
            tenantName: i.tenant,
            tenantId: i.login.split('.')[0],
            apiUrl: '',
        }))
        .filter((i: LastLogin) => !existingUserIds.includes(i.userId));

    return data.length < MAX_LAST_LOGINS
        ? data.concat(
              take<LastLogin>(storageItems, MAX_LAST_LOGINS - data.length)
          )
        : data;
};

export const useLastLoginsModel = (): LastLoginsModel => {
    const queryClient = useQueryClient();
    const { redirectAppData } = useLoginStore();

    const { data: lastLogins, isFetched } = useQuery({
        queryKey: ['lastLogins'],
        queryFn: () => getAllLastLogins(),
        initialData: [],
        staleTime: 0,
        select: d => mergeLastLoginsWithStorage(d, redirectAppData.appKey),
    });

    const { mutateAsync: handleSetLastLogin } = useMutation({
        mutationFn: () => setLastLogin(),
    });

    const { mutateAsync: handleRemoveLastLogin } = useMutation({
        mutationFn: (userId: string) => removeLastLogin(userId),
        onSuccess: () =>
            queryClient.invalidateQueries({ queryKey: ['lastLogins'] }),
    });

    return {
        isFetched,
        lastLogins,
        handleSetLastLogin,
        handleRemoveLastLogin,
    };
};
