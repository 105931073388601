import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { noop } from 'lodash-es';

import { AppIcon } from '../Icon/AppIcon/AppIcon';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { toClasses } from '../../utils';
import { DialogSize } from './Dialog.types';

export const DIALOG_CONTAINER_ID = 'dialog-window';

interface Props {
    isOpened: boolean;
    title?: string;
    closeIcon?: boolean;
    children?: React.ReactNode;
    onClose?: () => void;
    autoClose?: boolean;
    className?: string;
    size?: DialogSize;
}

const container = document.createElement('div');
container.id = DIALOG_CONTAINER_ID;
container.className = DIALOG_CONTAINER_ID;
document.body.appendChild(container);

export const DialogBase = ({
    title,
    closeIcon,
    children,
    onClose = noop,
    autoClose = true,
    className = '',
    size,
}: Props) => {
    const contentContainer = useRef<HTMLDivElement>(null);

    useOnClickOutside(contentContainer, onClose, autoClose, [`.dialog-wrap:not(.${className.split(' ').join('.')})`]);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => document.body.classList.remove('no-scroll');
    }, []);

    return (
        <div className={toClasses('dialog-wrap', size, className)}>
            <div ref={contentContainer} className="dialog-content">
                <div className="dialog-header">
                    {title && <div className="dialog-title">{title}</div>}
                    {closeIcon && (
                        <div className="dialog-close-btn" onClick={onClose}>
                            <AppIcon name="close" />
                        </div>
                    )}
                </div>
                <div className="dialog-body">{children}</div>
            </div>
        </div>
    );
};

export const Dialog = (props: Props) => (props.isOpened ? createPortal(<DialogBase {...props} />, container) : null);
