import React from 'react';
import { isBoolean, isUndefined } from 'lodash-es';

import { Omit } from '../../types';
import { toClasses, inCurrent } from '../../utils';
import DropdownItem from './Item';
import { moveUp, moveDown } from './';
import { TextTruncate } from '../TextTruncate/TextTruncate';

export interface DropdownDataItem<V> {
    id: string;
    label: string;
    value: V;
    itemClass?: string;
    children?: DropdownDataItem<V>[];
}

export interface Props<V> extends Omit<React.HTMLProps<HTMLDivElement>, 'onSelect'> {
    isExpanded?: boolean;
    active?: number;
    items: Array<DropdownDataItem<V>>;
    component?: React.ComponentType<DropdownDataItem<V>>;
    listComponent?: React.ComponentType<DropdownDataItem<V>>;
    onSelect: (value: V, id?: string) => void;
    children: React.ReactNode;
    selectedId?: string | string[];
    hideOnSelect?: boolean;
}

export function Dropdown<V>({
    items,
    children,
    onSelect,
    component,
    className,
    selectedId = '',
    disabled,
    hideOnSelect,
    ...props
}: Props<V>) {
    const [isVisible, setListVisibility] = React.useState(!!props.isExpanded);
    const [active, setActive] = React.useState<number | undefined>(props.active);
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const classes = React.useMemo(
        () => toClasses('dropdown', className, isVisible && 'is-expanded', disabled && 'is-disabled'),
        [className, isVisible, disabled]
    );
    const canRenderList = React.useMemo(() => isVisible && items.length > 0, [isVisible, items]);

    const handleItemSelect = React.useCallback(
        (value: V, id: string) => {
            onSelect(value, id);
            hideOnSelect && setListVisibility(false);
        },
        [onSelect, hideOnSelect]
    );

    const handleMoveUp = React.useCallback(() => {
        if (isVisible) {
            setActive(moveUp(items.length - 1));
        }
    }, [isVisible, items]);

    const handleMoveDown = React.useCallback(() => {
        if (isVisible) {
            setActive(moveDown(items.length));
        }
    }, [isVisible, items]);

    const handleEscPress = React.useCallback(() => setListVisibility(false), []);

    const handleEnterPress = React.useCallback(() => {
        if (isVisible && !isUndefined(active)) {
            onSelect(items[active].value);
            setListVisibility(false);
        }
    }, [active, items, isVisible, onSelect]);

    const handleDocumentClick = React.useCallback((e: MouseEvent) => {
        if (inCurrent(dropdownRef.current, e.target as HTMLElement)) {
            toggleDropdown();
        } else {
            setListVisibility(false);
        }
    }, []);

    const handleDocumentKeyPress = React.useCallback(
        (event: KeyboardEvent) => {
            switch (event.key) {
                case 'Enter':
                    return handleEnterPress();
                case 'Escape':
                    return handleEscPress();
                case 'ArrowDown': {
                    event.preventDefault();
                    return handleMoveDown();
                }
                case 'ArrowUp': {
                    event.preventDefault();
                    return handleMoveUp();
                }
            }
        },
        [handleEnterPress, handleEscPress, handleMoveDown, handleMoveUp]
    );

    const toggleDropdown = React.useCallback(() => setListVisibility((prevState) => !prevState), []);

    React.useEffect(() => {
        if (disabled) {
            return;
        }
        document.addEventListener('click', handleDocumentClick);
        return () => document.removeEventListener('click', handleDocumentClick);
    }, [disabled]);

    React.useEffect(() => {
        document.addEventListener('keydown', handleDocumentKeyPress);
        return () => document.removeEventListener('keydown', handleDocumentKeyPress);
    }, [handleDocumentKeyPress]);

    React.useEffect(() => {
        if (isBoolean(props.isExpanded)) {
            setListVisibility(props.isExpanded);
        }
    }, [props.isExpanded]);

    return (
        <div className={classes} tabIndex={0} {...props}>
            <div className="dropdown-trigger" ref={dropdownRef}>
                <TextTruncate lineCount={2}>{children}</TextTruncate>
            </div>
            {canRenderList && (
                <ul className="dropdown-list">
                    {items.map(({ id, label, value, itemClass }, index) => (
                        <DropdownItem
                            selected={selectedId}
                            className={itemClass}
                            key={id}
                            isActive={index === active}
                            {...{ id, label, value, component }}
                            onClick={handleItemSelect}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Dropdown;
