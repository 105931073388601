import React from 'react';

import Step from './Step';
import { toClasses } from '../../../utils';
import usePrevious from '../../../hooks/usePrevious';

interface Props {
    className?: string;
    currentStep: number;
    onSubmit: (form: HTMLFormElement) => void;
    children: React.ReactNode;
}

const StepsForm = ({ className, onSubmit, currentStep, children }: Props) => {
    const previousStep = usePrevious(currentStep);

    const classes = React.useMemo(() => toClasses('step-form', className), [className]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit(event.target as HTMLFormElement);
        },
        [onSubmit]
    );

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') event.preventDefault();
    };

    const steps = React.useMemo(
        () =>
            React.Children.map(children, (child, index) => (
                <Step ownPosition={index} activePosition={currentStep} previousPosition={previousStep}>
                    {currentStep === index ? child : null}
                </Step>
            )),
        [currentStep, children]
    );

    return (
        <form className={classes} onSubmit={handleSubmit} onKeyDownCapture={handleKeyDown}>
            {steps}
        </form>
    );
};

export default StepsForm;
