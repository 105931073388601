import React, { CSSProperties, useRef } from 'react';
import { forIn, isEmpty } from 'lodash-es';

interface Props {
    src: string;
    alt?: string;
    className?: string;
    width?: string;
    height?: string;
    imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

interface ContainerSize {
    width: number;
    height: number;
}

const moveByXAxis = (imageCurrentWidth: number, containerWidth: number) =>
    imageCurrentWidth > containerWidth ? `translateX(-${(imageCurrentWidth - containerWidth) / 2}px)` : '';

const moveByYAxis = (imageCurrentHeight: number, containerHeight: number) =>
    imageCurrentHeight > containerHeight ? `translateY(-${(imageCurrentHeight - containerHeight) / 2}px)` : '';

export const CenteredImage = ({ src, className = '', alt = '', width, height, imageProps }: Props) => {
    const [imageNaturalSize, setImageNaturalSize] = React.useState<{
        intrinsicWidth: number;
        intrinsicHeight: number;
    } | null>(null);
    const [containerSize, setContainerSize] = React.useState<ContainerSize | null>({ width: 0, height: 0 });
    const [imageStyles, setImageStyles] = React.useState<CSSProperties>({});
    const [imgElement, setImgElement] = React.useState<HTMLImageElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const updateContainerSize = React.useCallback(() => {
        const container = containerRef.current as HTMLDivElement | null;

        if (container) {
            setContainerSize({
                width: container.offsetWidth,
                height: container.offsetHeight,
            });
        }
    }, [containerRef.current]);

    React.useEffect(() => {
        if (!isEmpty(imageStyles) && imgElement) {
            // @ts-ignore
            forIn(imageStyles, (value: string, key: keyof CSSProperties) => {
                // @ts-ignore
                imgElement.style[key] = value;
            });
        }
    }, [imageStyles, imgElement]);

    React.useEffect(() => {
        if (imgElement) {
            imgElement.alt = alt;
        }
    }, [alt]);

    React.useEffect(() => {
        if (src) {
            const img = document.createElement('img');
            img.src = src;
            img.className = 'centered-image';
            img.onload = () => {
                setImageNaturalSize({
                    intrinsicWidth: img.naturalWidth,
                    intrinsicHeight: img.naturalHeight,
                });
            };

            if (imageProps) Object.entries(imageProps).map(([key, attribute]) => img.setAttribute(key, attribute));

            setImgElement(img);
        }
    }, [src, imageProps]);

    React.useEffect(() => {
        if (containerRef.current && imgElement) {
            containerRef.current?.replaceChildren(imgElement);
        }
    }, [containerRef.current, imgElement]);

    React.useLayoutEffect(() => {
        updateContainerSize();
    }, [updateContainerSize, containerRef.current?.offsetWidth, containerRef.current?.offsetHeight]);

    React.useEffect(() => {
        window.addEventListener('resize', updateContainerSize);
        return () => window.removeEventListener('resize', updateContainerSize);
    }, []);

    React.useEffect(() => {
        if (containerSize && imageNaturalSize) {
            const { intrinsicWidth, intrinsicHeight } = imageNaturalSize;
            const { width: contWidth, height: contHeight } = containerSize;
            const largerImageRatio = intrinsicWidth / intrinsicHeight > contWidth / contHeight;
            let styles: CSSProperties = {};
            const imageCurrentHeight = intrinsicHeight / (intrinsicWidth / contWidth);
            const imageCurrentWidth = intrinsicWidth / (intrinsicHeight / contHeight);
            styles = {
                width: largerImageRatio ? 'auto' : '100%',
                height: largerImageRatio ? '100%' : 'auto',
                transform: largerImageRatio
                    ? moveByXAxis(imageCurrentWidth, contWidth)
                    : moveByYAxis(imageCurrentHeight, contHeight),
            };

            setImageStyles(styles);
        }
    }, [imageNaturalSize, containerSize]);

    return (
        <div
            ref={containerRef}
            style={{ width, height }}
            className={`centered-image-container${className ? ' ' + className : ''}`}
        ></div>
    );
};
