import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { get, noop } from 'lodash-es';
import { useNavigate, useParams } from 'react-router';
import LoginForm from '../../../ui-toolkit/components/Login/LoginForm';
import { LocalUser, Person } from '../../../ui-toolkit/types';
import { useLocation } from 'react-router-dom';
import {
    getSsoSettings,
    getSsoSettingsForTenant,
    getUserThumbnail,
} from '../../api/login.api.ts';
import { Avatar } from '../../../ui-toolkit/components/Avatar/Avatar.tsx';
import { useTranslation } from 'react-i18next';
import { useLoginModel } from './useLoginModel.ts';
import { useLoginStore } from '../../store/login.store.ts';
import {
    getForgotPasswordHandler,
    loginViaSSO,
} from '../../utils/login.utils.ts';
import { ErrorBoundary } from 'ui-toolkit/components/ErrorBoundary/ErrorBoundary.tsx';
import { api } from '../../api/apiConfig.ts';
import Spinner from 'ui-toolkit/components/Spinner';
import { LOGO } from '../../../public/logo-base64.ts';
import { blobToBase64 } from 'ui-toolkit/utils/cryptography.utils.ts';

interface LoginRouteProps {
    tenantId: string;
}

interface Props {
    onError: () => void;
}

export const Login = ({ onError = noop }: Props) => {
    const { tenantId } = useParams<keyof LoginRouteProps>();
    const location = useLocation();
    const passwordExpired = useLoginStore(store => store.passwordExpired);
    const loginStore = useLoginStore(store => store);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { onLogIn, lastLogins, isLastLoginsFetched, handleRemoveLastLogin } =
        useLoginModel();
    const noSSO = useMemo(
        () => get(location.state, 'ssoFailed', false),
        [location]
    );

    const getAvatar = React.useCallback(
        (user: LocalUser) => (
            <Avatar
                className="user-card__avatar"
                image={user.avatar || undefined}
                person={{ name: user.name, id: user.login } as Person}
                showInitials={true}
                personPromise={() =>
                    getUserThumbnail(
                        user.lastLoginData.tenantId,
                        user.lastLoginData.userId
                    ).then(data => blobToBase64(data))
                }
                placeholderPromise={() => Promise.resolve('')}
            />
        ),
        []
    );

    const mappedLastLogins = useMemo(
        () =>
            lastLogins.map(u => ({
                name: u.userName,
                tenant: u.tenantName,
                login: u.userId,
                avatar: null,
                lastLoginData: u,
            })),
        [lastLogins]
    );

    useEffect(() => {
        if (passwordExpired)
            navigate('/change-password', { state: { autoLogin: true } });
    }, [navigate, passwordExpired]);
    if (!isLastLoginsFetched) return <Spinner enableBackground />;

    return (
        <ErrorBoundary>
            <LoginForm
                {...{ translate: t, history, onError }}
                logo={LOGO}
                appTitle={loginStore.appTitleKey}
                className="gray-bg"
                isCaptchaRequired={loginStore.isCaptchaRequired}
                fetchSettings={(
                    id: string,
                    organization?: string,
                    baseUrl?: string
                ) =>
                    getSsoSettings(id, organization, baseUrl).then(data => {
                        if (data.apiUrl && data.apiUrl !== api.getBaseURL())
                            api.setBaseURL(data.apiUrl);
                        return data;
                    })
                }
                fetchTenantSettings={getSsoSettingsForTenant}
                onForgotPassword={getForgotPasswordHandler(navigate, tenantId)}
                onLogIn={onLogIn}
                onSSOLogIn={loginViaSSO(
                    location,
                    tenantId,
                    loginStore.redirectAppData
                )}
                noSSO={noSSO}
                getAvatar={getAvatar}
                handleRemoveLastLogin={handleRemoveLastLogin}
                tenantId={tenantId}
                lastLogins={mappedLastLogins}
            />
        </ErrorBoundary>
    );
};
