import React from 'react';
import { noop, debounce } from 'lodash-es';

import { toClasses } from '../../utils';
import { AppIcon } from '../Icon/AppIcon/AppIcon';

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'value'> {
    value?: string;
    trimValue?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onClear?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    debounceDelay?: number;
}

const initialValue = '';

const SearchInput = React.forwardRef<HTMLInputElement, Props>(
    ({ className, onFocus, onBlur, name = 'search', trimValue, onClear, debounceDelay = 0, ...props }, ref) => {
        const [value, setValue] = React.useState(props.value || initialValue);
        const classes = React.useMemo(() => toClasses('search-input-wrapper', className), [className]);

        const onChange = React.useMemo(() => (props.onChange ? debounce(props.onChange, debounceDelay) : noop), [
            debounceDelay,
            props?.onChange,
        ]);

        const handleChange = React.useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                let newValue = event.target.value;

                if (trimValue) {
                    newValue = newValue.replace(/^(\s*)?(.*)$/, (match, p1, p2) => p2.replace(/\s+/g, ' '));
                }

                if (newValue !== value) {
                    setValue(newValue);
                    onChange(event);
                }
            },
            [value, onChange]
        );

        const handleClear = React.useCallback(
            (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                setValue(initialValue);
                if (onClear) {
                    onClear(e);
                }
            },
            [onClear]
        );

        React.useEffect(() => {
            setValue(props.value || initialValue);
        }, [props.value]);

        return (
            <div className={classes}>
                <AppIcon className="search-input-search-icon" name="search" />
                <input
                    {...props}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    ref={ref}
                    type="text"
                    name={name}
                    value={value}
                    autoComplete="off"
                    className="search-input"
                    onChange={handleChange}
                />
                {value.length > 0 && (
                    <span className="search-input-clear-icon-wrapper" onClick={handleClear}>
                        <AppIcon className="search-input-clear-icon" name="close" />
                    </span>
                )}
            </div>
        );
    }
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
