import React from 'react';
import { noop } from 'lodash-es';

import { PasswordRulesDTO } from '../../types';
import { AppIcon } from '../Icon/AppIcon/AppIcon';
import { TranslateFunction } from '../../types/translations';
import { Translate } from '../Translate/Translate';

interface Props {
    className?: string;
    translate: TranslateFunction;
    getRules: () => Promise<PasswordRulesDTO>;
    onError?: (error: Error) => void;
}

const PasswordHint = ({ getRules, onError = noop, className, translate }: Props) => {
    const [rules, setRules] = React.useState<PasswordRulesDTO>();
    const [isHintVisible, setHintVisibility] = React.useState(false);

    const showHint = React.useCallback(() => setHintVisibility(true), []);
    const hideHint = React.useCallback(() => setHintVisibility(false), []);

    React.useEffect(() => {
        getRules()
            .then((resp: PasswordRulesDTO) => setRules(resp))
            .catch(onError);
    }, []);

    const mandatoryCharactersMsg = React.useMemo(() => {
        const { mandatoryCharacterClasses } = rules || { mandatoryCharacterClasses: [] as string[] };
        const rulesLabels = mandatoryCharacterClasses
            .map((rule: string) => translate(`auth.passwordRules.${rule}`).toString().toLowerCase())
            .join(', ');
        return translate('auth.passwordRules.mandatoryCharacterClasses', { ch: rulesLabels });
    }, [rules]);

    return rules && (rules.passwordMinLength || rules.validateRestricted || rules.mandatoryCharacterClasses.length) ? (
        <div className={`password__hint ${className}`} onMouseEnter={showHint} onMouseLeave={hideHint}>
            <AppIcon name="info-circle" />
            {isHintVisible && (
                <div className="password__hint-rules">
                    <div className="password__hint-container">
                        {!!rules.passwordMinLength && (
                            <p className="password__hint-rule">
                                {translate('auth.passwordRules.passwordMinLength', {
                                    n: rules.passwordMinLength.toString(),
                                })}
                            </p>
                        )}
                        {rules.validateRestricted && (
                            <p className="password__hint-rule">
                                <Translate id={'auth.passwordRules.validateRestricted'} />
                            </p>
                        )}
                        {!!rules.mandatoryCharacterClasses.length && (
                            <p className="password__hint-rule">{mandatoryCharactersMsg}</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    ) : null;
};

PasswordHint.defaultProps = {
    className: '',
};

// @ts-ignore
export default PasswordHint;
