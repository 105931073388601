// eslint-disable-next-line no-shadow
export enum LoginFormMode {
    Normal,
    SSO,
}

// eslint-disable-next-line no-shadow
export enum UIType {
    success = 'success',
    danger = 'danger',
    warning = 'warning',
    default = 'default',
    none = 'none',
}

export const LOCAL_USERS_KEY = 'local-users';
export const LOCAL_USERS_LIMIT = 4;

export const ENTER_KEY_CODE = 13;

export const DEFAULT_ORGANIZATION_NAME = 'Spacewell';

export const LIGHT_BRAND_COLOR = 'rgba(0, 166, 181, 1)';
export const DARK_BRAND_COLOR = 'rgb(6, 72, 139)';
export const SECONDARY_FONT_COLOR = 'rgba(135, 135, 135, 1)';

export const EXTRA_SMALL = 320;
export const SMALL = 576;
export const MEDIUM = 768;
export const LARGE = 992;
export const EXTRA_LARGE = 1200;
export const MEDIA = {
    EXTRA_LARGE,
    LARGE,
    MEDIUM,
    SMALL,
    EXTRA_SMALL,
};

export const ALL_KEY = 'ALL';
export const DEVICE_KEY = 'DEVICE_KEY';
export const USER_KEY = 'USER_KEY';
export const CATEGORY_KEY = 'CATEGORY_KEY';
