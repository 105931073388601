import { setBaseURL } from '../api/apiConfig.ts';

export const defaultLanguage = 'en';
export const availableLang = [
    'en',
    'nl',
    'fr',
    'de',
    'sv',
    'da',
    'es',
] as const;

export type AvailableLang = (typeof availableLang)[number];
export type LanguagesPaths = { [key in AvailableLang]: string };

export const logoPath = setBaseURL('spacewell-logo-full-size.svg');
export const ssoImagePath = setBaseURL('sso-cloud.svg');
