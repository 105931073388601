import React from 'react';
import { get } from 'lodash-es';
import Recaptcha from 'react-recaptcha';

import { ChangePasswordData, PasswordRulesDTO, PasswordExpiredData } from '../../../types';
import { LoginFormTemplate } from '../LoginFormTemplate/LoginFormTemplate';
import Button from '../../Button';
import PasswordHint from '../../PasswordField/PasswordHint';
import { LoginErrorCodes } from '../../../constants/login';
import Alert from '../../../components/Alert/Alert';
import { Translate } from '../../Translate/Translate';
import { TranslateFunction } from '../../../types/translations';
import PasswordField from '../../PasswordField/PasswordField';
import Spinner from '../../Spinner';

interface Props {
    logo: string;
    title?: string;
    className?: string;
    translate: TranslateFunction;
    userData?: PasswordExpiredData | null;
    isCaptchaRequired: boolean;
    isLoading?: boolean;
    isOrganizationRequired: boolean;
    setError: (content: string) => void;
    setPasswordIsNotRequired: () => void;
    changePassword: (data: ChangePasswordData) => Promise<unknown>;
    withErrorAlert?: boolean;
    getRules: () => Promise<PasswordRulesDTO>;
    state: { autoLogin?: boolean };
}

function getChangePasswordData(
    controls: HTMLFormControlsCollection,
    state: { autoLogin?: boolean },
    captcha: string
): ChangePasswordData {
    return {
        // @ts-ignore
        username: get(controls['username'], 'value', ''),
        // @ts-ignore
        password: get(controls['password'], 'value', ''),
        // @ts-ignore
        newPassword: get(controls['newPassword'], 'value', ''),
        // @ts-ignore
        organization: get(controls['organization'], 'value', undefined),
        captcha,
        autoLogin: get(state, 'autoLogin', false) || false,
    };
}

export const ChangePassword = ({
    logo,
    title = 'auth.title',
    userData,
    translate,
    setError,
    changePassword,
    isCaptchaRequired,
    isOrganizationRequired,
    setPasswordIsNotRequired,
    withErrorAlert,
    getRules,
    isLoading: containerLoader,
    state,
}: Props) => {
    const { username = '', password = '', organization = '' } = userData || {};
    const [isLoading, setLoading] = React.useState(false);
    const [currentPassword, setCurrentPassword] = React.useState(password);
    const [newPassword, setNewPassword] = React.useState('');
    const [isPasswordMatched, setPasswordMatched] = React.useState(true);
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const [captcha, setCaptcha] = React.useState('');
    const [errors, setErrors] = React.useState<string[]>([]);

    const handlePasswordChange = React.useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(target.value),
        [password]
    );

    const handleNewPasswordChange = React.useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = target;
            setNewPassword(value as string);
            if (!isPasswordMatched) {
                setPasswordMatched(confirmedPassword === value);
            }
        },
        [confirmedPassword, isPasswordMatched]
    );

    const handleConfirmPasswordChanged = React.useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => setConfirmedPassword(target.value),
        []
    );

    const handleSubmit = React.useCallback(
        (event: React.ChangeEvent<HTMLFormElement>) => {
            event.preventDefault();
            setLoading(true);

            changePassword(getChangePasswordData(event.target.elements, state, captcha))
                .then(() => {
                    setLoading(false);
                    setPasswordIsNotRequired();
                })
                .catch((errorResponse) => {
                    if (Array.isArray(errorResponse)) {
                        errorResponse = errorResponse[1].data;
                    }
                    /*
                     * Because studio and GO have different response format we need to check where error data is
                     * */
                    if (
                        errorResponse.code === LoginErrorCodes.MULTIPLE_ERRORS ||
                        (errorResponse.data && errorResponse.data.code === LoginErrorCodes.MULTIPLE_ERRORS)
                    ) {
                        const err =
                            errorResponse.data && errorResponse.data.code === LoginErrorCodes.MULTIPLE_ERRORS
                                ? errorResponse.data.errors
                                : errorResponse.errors;
                        setErrors(err.map(({ message }: Error) => message));
                    }
                    setLoading(false);
                    setError(errorResponse.message);
                });
        },
        [history, captcha, state]
    );

    const handleClearError = React.useCallback(() => setErrors([]), []);

    return (
        <LoginFormTemplate logo={logo} title={translate(title) as string}>
            {containerLoader && <Spinner fullContainerSize enableBackground />}
            <form className="login-form change-password" onSubmit={handleSubmit}>
                {withErrorAlert && !!errors.length && (
                    <Alert className={'change-password-error-alert'} bsStyle="danger" onDismiss={handleClearError}>
                        {errors.map((error) => (
                            <div key={error} className={'error'}>
                                {error}
                            </div>
                        ))}
                    </Alert>
                )}
                <input className={'form-control'} type="hidden" name="username" value={username} />
                {isOrganizationRequired && (
                    <input className={'form-control'} type="hidden" name="organization" value={organization} />
                )}
                <PasswordField
                    name="password"
                    required={true}
                    value={currentPassword}
                    translate={translate}
                    inputClasses={'login-input'}
                    onChange={handlePasswordChange}
                    label={translate('auth.currentPassword') as string}
                    placeholder={translate('auth.password') as string}
                />
                <div className={'password-input-wrap'}>
                    <PasswordField
                        name="newPassword"
                        required={true}
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        userInputs={[username, organization]}
                        translate={translate}
                        strength={!!(newPassword && isPasswordMatched)}
                        className={isPasswordMatched ? '' : 'has-error'}
                        inputClasses={'login-input'}
                        selfStrength={!!(isPasswordMatched && newPassword)}
                        placeholder={translate('forgot.reset.passwordFiledPlaceholder') as string}
                    />
                    {username && (
                        <PasswordHint translate={translate} getRules={getRules as () => Promise<PasswordRulesDTO>} />
                    )}
                </div>

                <PasswordField
                    name="confirmedPassword"
                    required={true}
                    value={confirmedPassword}
                    onChange={handleConfirmPasswordChanged}
                    className={isPasswordMatched ? '' : 'has-error'}
                    inputClasses={'login-input'}
                    translate={translate}
                    placeholder={translate('auth.confirmPassword') as string}
                    onBlur={() => setPasswordMatched(newPassword === confirmedPassword)}
                >
                    {!isPasswordMatched && (
                        <span className="help-block">
                            <Translate id={'auth.password.notMatch'} />
                        </span>
                    )}
                </PasswordField>
                {isCaptchaRequired && (
                    <Recaptcha sitekey="6LdfaW0UAAAAAAPUo66GgwRS7Q8ZCl2nAQSpAGaL" verifyCallback={setCaptcha} />
                )}
                <Button
                    type="submit"
                    isLoading={isLoading}
                    isRounded
                    color="brand"
                    className="login-proceed-btn"
                    disabled={isLoading || (!newPassword && !confirmedPassword) || newPassword !== confirmedPassword}
                >
                    <Translate id={'auth.updatePassword'} />
                </Button>
            </form>
        </LoginFormTemplate>
    );
};

export default ChangePassword;
