/*eslint-disable no-magic-numbers*/
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { IdpSetting, LastLoginData, LocalUser, SSOSettings } from '../../../types';
import LoginFirstStep from './FirstStep';
import LoginSecondStep from './SecondStep';
import LoginThirdStep from './ThirdStep';
import LoginFourthStep from './FourthStep';
import usePrevious from '../../../hooks/usePrevious';
import StepsForm from '../StepsForm';
import { LoginFormTemplate } from '../LoginFormTemplate/LoginFormTemplate';
import { TranslateFunction } from '../../../types/translations';
import { IdpSelect } from '../IdpSelect/IdpSelect';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash-es';
import { isEmail } from '../../../utils';
import { getCachedLoginTenants } from '../../../utils/login';
import { DropdownDataItem } from '../../Dropdown/Dropdown';

interface Props {
    logo: string;
    title?: string;
    tenantId?: string;
    subtitle?: string;
    className?: string;
    appTitle: string;
    noSSO?: boolean;
    translate: TranslateFunction;
    isCaptchaRequired: boolean;
    fetchSettings: (username: string, organization?: string, baseUrl?: string) => Promise<SSOSettings>;
    fetchTenantSettings: (tenantId: string) => Promise<SSOSettings>;
    onLogIn: (
        username: string,
        password: string,
        captcha: string,
        organization: string,
        saveLoginToLs?: boolean
    ) => Promise<boolean>;
    onSSOLogIn: (
        username: string,
        settings: SSOSettings | undefined,
        organization?: string,
        idpId?: string
    ) => Promise<boolean>;
    onForgotPassword: () => void;
    getAvatar: (user: LocalUser) => ReactNode;
    handleRemoveLastLogin: (userId: string) => void;
    lastLogins: LocalUser[];
}

export interface IdpState {
    idpItems: DropdownDataItem<IdpSetting>[];
    settings: SSOSettings;
    organization?: string;
}

const idpItemsToDropdown = (idpList: IdpSetting[]) =>
    idpList.map((i: IdpSetting) => ({ id: i.id, label: i.name, value: i }));

const LoginForm = ({
    logo,
    translate,
    isCaptchaRequired,
    fetchSettings,
    onSSOLogIn,
    onLogIn,
    onForgotPassword,
    getAvatar,
    appTitle,
    fetchTenantSettings,
    handleRemoveLastLogin,
    lastLogins = [],
    noSSO = false,
    tenantId: tenantFromUrl,
}: Props) => {
    const hasLocalUsers = React.useMemo(() => lastLogins.length > 0, [lastLogins]);
    const location = useLocation();
    const [formStep, setFormStep] = React.useState(hasLocalUsers ? 0 : 1);
    const [username, setUsername] = React.useState('');
    const [tenantId, setTenant] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const [tenantFromUrlInited, setTenantFromUrlInited] = React.useState(false);
    const [shouldSaveLoginId, setShouldSaveLoginId] = React.useState(false);
    const previousStep = usePrevious(formStep);
    const [activeIdpsState, setActiveIpdsState] = useState<IdpState | null>(null);

    const getUserLogin = React.useCallback(
        (settings: SSOSettings, userLogin: string, organization?: string, idpId?: string, hideLoader = true) => {
            setLoading(true);
            return (
                onSSOLogIn(userLogin, settings, organization, idpId)
                    .then((status) => [status, settings])
                    .catch(() => [false, settings])
                    // @ts-ignore
                    .then(([status]: [boolean, SSOSettings]) => {
                        if (status) {
                            // We have been successfully logged in via SSO so it not necessary to proceed
                            return status;
                        }
                        if (settings && settings.isOrganizationRequired) {
                            if (tenantFromUrl) {
                                setTenant(tenantFromUrl);
                                setFormStep(3);
                            } else setFormStep(2);

                            return true;
                        }
                        setFormStep(3);
                        return true;
                    })
                    .catch(() => false)
                    .finally(() => hideLoader && setLoading(false))
            );
        },
        [onSSOLogIn, tenantFromUrl, translate]
    );

    const checkSettings = useCallback(
        (userLogin: string, organization?: string, baseUrl?: string) => {
            setUsername(userLogin);
            return fetchSettings(userLogin, organization, baseUrl)
                .then((settings: SSOSettings) => {
                    if (!noSSO && settings.idpList.length > 1)
                        setActiveIpdsState({
                            idpItems: idpItemsToDropdown(settings.idpList),
                            settings,
                            organization,
                        });
                    else getUserLogin(settings, userLogin, organization);
                    return true;
                })
                .catch(() => false);
        },
        [getUserLogin, noSSO]
    );

    const name = React.useMemo(() => {
        const localUser = lastLogins.find(({ login }) => login === username);
        if (localUser) {
            return localUser.name;
        }
        if (username.indexOf('@') >= 1) {
            const [prefix] = username.split('@');
            return prefix.toUpperCase();
        }
        if (username.indexOf('.') >= 1) {
            const [, suffix] = username.split('.');
            return suffix.toUpperCase();
        }
        return username.toUpperCase();
    }, [username]);

    const handleSubmit = React.useCallback(
        ({ elements }: HTMLFormElement) => {
            setLoading(true);
            onLogIn(
                // @ts-ignore
                elements['login'].value, // @ts-ignore
                elements['password'].value, // @ts-ignore
                elements['captcha'].value, // @ts-ignore
                elements['organization'].value,
                shouldSaveLoginId
            ).finally(() => setLoading(false));
        },
        [shouldSaveLoginId]
    );

    const headings = React.useMemo(
        () => ({
            0: { title: translate(appTitle), subTitle: translate('auth.subTitle') },
            1: { title: translate(appTitle), subTitle: translate('auth.subTitle') },
            2: {
                title: `${translate('common.hello')} ${name}`,
                subTitle: `${translate('auth.multipleTenantsAccount')} \n ${translate('auth.shareTenantId')}`,
            },
            3: { title: `${translate('common.hello')} ${name}`, subTitle: translate('auth.providePassword') },
        }),
        [name, translate, appTitle]
    );

    const handleSubmitIdp = useCallback(
        (id: string) => {
            if (activeIdpsState) {
                setActiveIpdsState(null);
                getUserLogin(activeIdpsState.settings, username, activeIdpsState.organization, id, false);
            }
        },
        [activeIdpsState, username]
    );

    const handleLoginWithLastLogin = useCallback(
        (data: LastLoginData) => checkSettings(data.userId, data.tenantId, data.apiUrl),
        [checkSettings]
    );

    const handleFirstTimeLogin = useCallback(
        (userName: string) => {
            if (tenantFromUrl && !isEmail(userName)) {
                setUsername(
                    userName.startsWith(`${tenantFromUrl}.`) || isEmail(userName)
                        ? userName
                        : `${tenantFromUrl}.${userName}`
                );
                setFormStep(3);
                return Promise.resolve(true);
            } else return checkSettings(userName);
        },
        [tenantFromUrl, checkSettings, onSSOLogIn]
    );

    const cachedTenantsList = useMemo(() => getCachedLoginTenants(username), [username]);

    useEffect(() => {
        if (tenantFromUrl && !tenantFromUrlInited) {
            setLoading(true);
            let isSsoRedirectFlow = false;
            fetchTenantSettings(tenantFromUrl)
                .then((settings: SSOSettings) => {
                    if (!settings.samlEnabled || get(location.state, 'ssoFailed', false)) return setFormStep(1);

                    if (settings.idpList.length > 1) {
                        setActiveIpdsState({
                            idpItems: idpItemsToDropdown(settings.idpList),
                            settings,
                            organization: tenantFromUrl,
                        });
                        setUsername(`${tenantFromUrl}.`);
                    } else {
                        isSsoRedirectFlow = true;
                        getUserLogin(settings, `${tenantFromUrl}.`, tenantFromUrl, undefined, false);
                    }
                })
                .finally(() => {
                    !isSsoRedirectFlow && setLoading(false);
                    setTenantFromUrlInited(true);
                });
        }
    }, [tenantFromUrl, getUserLogin, tenantFromUrlInited, location.state]);

    if (activeIdpsState && !get(location.state, 'ssoFailed', false)) {
        return (
            // @ts-ignore
            <LoginFormTemplate isLoading={isLoading} logo={logo} {...headings[formStep]}>
                <IdpSelect username={username} items={activeIdpsState.idpItems} onSelect={handleSubmitIdp} />
            </LoginFormTemplate>
        );
    }

    return (
        // @ts-ignore
        <LoginFormTemplate isLoading={isLoading} logo={logo} {...headings[formStep]}>
            <StepsForm currentStep={formStep} onSubmit={handleSubmit}>
                {/*Second time login. User cards*/}
                <LoginFirstStep
                    {...{ translate, previousUsers: lastLogins }}
                    onRemoveUser={handleRemoveLastLogin}
                    onUseAnotherAccount={() => {
                        setFormStep(1);
                        setUsername('');
                    }}
                    onNextStep={handleLoginWithLastLogin}
                    getAvatar={getAvatar}
                />

                {/*Initial for state. First time form. Username or email*/}
                <LoginSecondStep
                    {...{ translate, hasLocalUsers, onForgotPassword, username }}
                    isActive={formStep === 1}
                    onNextStep={handleFirstTimeLogin}
                    onUseStoredAccounts={() => setFormStep(0)}
                    showBackBtn={!tenantFromUrl}
                />
                {/*Multi tenant selection form*/}
                <LoginThirdStep
                    fetchSettings={fetchSettings}
                    username={username}
                    storedTenants={cachedTenantsList}
                    onBack={() => setFormStep(hasLocalUsers ? 0 : 1)}
                    onNextStep={(organizationId, settings) => {
                        setTenant(organizationId);
                        setShouldSaveLoginId(true);
                        if (settings.idpList.length > 1) {
                            setActiveIpdsState({
                                idpItems: settings.idpList.map((i: IdpSetting) => ({
                                    id: i.id,
                                    label: i.name,
                                    value: i,
                                })),
                                settings,
                                organization: organizationId,
                            });
                        } else if (!settings.samlEnabled) setFormStep(3);
                        else getUserLogin(settings, username, organizationId);
                    }}
                />
                {/*Password input step*/}
                <LoginFourthStep
                    {...{ translate, isLoading, isCaptchaRequired, onForgotPassword }}
                    login={username}
                    organization={tenantId}
                    isActive={formStep === 3}
                    onBack={() => setFormStep(previousStep)}
                    onEnterPress={handleSubmit}
                />
            </StepsForm>
        </LoginFormTemplate>
    );
};

export default LoginForm;
