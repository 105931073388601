import { useLocation } from 'react-router-dom';
import { RemindPasswordForm } from '../../../ui-toolkit/components/Login/RemindPassword/RemindPasswordForm.tsx';
import { useTranslation } from 'react-i18next';
import { logoPath } from '../../constants/localization.constants.ts';
import { remindLoginPassword } from '../../api/login.api.ts';
import { useNavigate } from 'react-router';
import { ErrorBoundary } from 'ui-toolkit/components/ErrorBoundary/ErrorBoundary.tsx';
import { useCallback } from 'react';
import { useLoginStore } from '../../store/login.store.ts';
import {
    LOGIN_QUERY_APP_KEY,
    LOGIN_QUERY_APP_URL,
} from 'ui-toolkit/constants/login.ts';
import { api } from '../../api/apiConfig.ts';

export const RemindPassword = () => {
    const { state } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { redirectAppData } = useLoginStore();

    const handleRemindPassword = useCallback(
        (loginId: string) => {
            const { appURL, appKey } = redirectAppData;
            const url = `${api.getBaseURL()}?${LOGIN_QUERY_APP_KEY}=${appKey}&${LOGIN_QUERY_APP_URL}=${appURL}`;
            return remindLoginPassword(loginId, url);
        },
        [redirectAppData]
    );

    return (
        <ErrorBoundary>
            {/*@ts-ignore*/}
            <RemindPasswordForm
                translate={t}
                remindLoginPassword={handleRemindPassword}
                tenantId={state?.tenantId}
                logo={logoPath}
                className="gray-bg"
                navigate={navigate}
            />
        </ErrorBoundary>
    );
};
