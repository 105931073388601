import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false,
    supportedLngs: ['en', 'nl', 'fr', 'de', 'sv', 'da', 'es'],
    interpolation: {
        escapeValue: false,
    },
    returnNull: false,
});

export default i18n;
