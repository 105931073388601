/*eslint-disable no-magic-numbers*/
import React from 'react';

interface Props {
    expanded?: boolean;
    position?: 'right' | 'left';
    children: React.ReactNode;
}

const ITEM_BASE_HEIGHT = 34;
const ITEM_BASE_WIDTH = 150;

const SnowmanMenu = ({ expanded = false, children, position = 'right' }: Props) => {
    const [isExpanded, toggle] = React.useState<boolean>(expanded);
    const closeMenu = React.useCallback(() => toggle(false), []);
    const [posClasses, setPosClasses] = React.useState('');
    const btnRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        const toggleMenu = ({ target }: MouseEvent) => {
            if (btnRef.current && !btnRef.current.contains(target as Node)) {
                toggle(false);
            }
        };
        document.addEventListener('click', toggleMenu);
        return () => {
            document.removeEventListener('click', toggleMenu);
        };
    }, []);

    React.useEffect(() => {
        if (btnRef && btnRef.current) {
            const positionClasses = getPositionsToFix(btnRef.current!);
            setPosClasses(
                positionClasses.length
                    ? positionClasses.map((direction) => `${direction}-position`).join(' ')
                    : 'right-position'
            );
        }
    }, [btnRef]);

    return (
        <div className={`snowman-menu ${posClasses || position}`}>
            <button
                ref={btnRef}
                type="button"
                className="btn btn-link"
                aria-label="Dropdown menu"
                onClick={() => {
                    toggle(!isExpanded);
                }}
            >
                <div className="snowman-dot" />
            </button>
            {isExpanded && (
                <ul className="snowman-menu__items" onClick={closeMenu}>
                    {React.Children.map(children, (child, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index.toString()} className="snowman-menu__item">
                            {child}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SnowmanMenu;

function getPositionsToFix(el: Element) {
    const fixPositions = [];
    const approximateBtnHeight = 25;
    const { x, y } = el.getBoundingClientRect();
    if (x - ITEM_BASE_WIDTH < 0) {
        fixPositions.push('right');
    }
    if (x + ITEM_BASE_WIDTH > window.innerWidth) {
        fixPositions.push('left');
    }
    if (y - ITEM_BASE_HEIGHT < 0) {
        fixPositions.push('bottom');
    }
    if (y + approximateBtnHeight + ITEM_BASE_HEIGHT > window.innerHeight) {
        fixPositions.push('top');
    }
    return fixPositions;
}
