import React from 'react';
import { isUndefined, isArray } from 'lodash-es';

import { DropdownDataItem } from '../Dropdown';
import { isScrollable, toClasses } from '../../../utils';

interface Props<V> {
    id: string;
    value: V;
    label: string;
    isActive: boolean;
    component?: React.ComponentType<DropdownDataItem<V>>;
    onClick: (value: V, id: string) => void;
    className?: string;
    selected: string | string[];
}

function DropdownItem<V>({
    isActive,
    id,
    label,
    value,
    component: CustomItem,
    onClick,
    className = '',
    selected,
}: Props<V>) {
    const ref = React.useRef<HTMLLIElement>(null);

    const classes = React.useMemo(() => {
        let selectedClass;
        if (isArray(selected)) selectedClass = selected.some((i) => i === id) ? 'selected' : '';
        else selectedClass = selected === id ? 'selected' : '';

        return toClasses(className, 'dropdown-item', isActive && 'is-active', selectedClass);
    }, [isActive, selected, className, id]);

    const handleClick = React.useCallback(() => onClick(value, id), [id, value, onClick]);

    React.useEffect(() => {
        if (ref.current && isScrollable(ref.current.parentElement) && isActive) {
            ref.current.scrollIntoView({ block: 'nearest' });
        }
    }, [isActive]);

    return (
        <li ref={ref} className={classes} onClick={handleClick}>
            {isUndefined(CustomItem) ? label : <CustomItem {...{ id, label, value }} />}
        </li>
    );
}

export default DropdownItem;
