import React, { SyntheticEvent } from 'react';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Translate } from '../../Translate/Translate';
import { TranslateFunction } from '../../../types/translations';

interface Props {
    email: string;
    isLoading: boolean;
    onChange: (email: string) => void;
    next: () => void;
    translate: TranslateFunction;
}

const FirstStep = ({ email, isLoading, next, onChange, translate }: Props) => (
    <>
        <Input
            type="text"
            placeholder={translate('forgot.remind.fieldPlaceholder') as string}
            required={true}
            className="login-input"
            value={email}
            disabled={isLoading}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => onChange(String(e.currentTarget.value))}
        />
        <Button
            color={'brand'}
            isRounded={true}
            className={'login-proceed-btn'}
            onClick={email.length ? next : undefined}
        >
            <Translate id={'forgot.nextBtn'} />
            {isLoading && <span className="loader pull-right" />}
        </Button>
    </>
);

export default FirstStep;
