import * as React from 'react';

import AlertCmp from './Alert';
import { AlertItem } from '../../types/alert';

const DEFAULT_LIFE_TIME = 5000;

interface AlertsProps {
    alerts: AlertItem[];
    lifeTime?: number;
    onDismiss?: (alert: AlertItem) => void;
}

class Alerts extends React.Component<AlertsProps> {
    public state = {
        alerts: new Map<string, AlertItem>(),
    };

    // eslint-disable-next-line camelcase
    public UNSAFE_componentWillReceiveProps(nextProps: AlertsProps) {
        const alerts = new Map();
        nextProps.alerts.map((alert: AlertItem) => {
            if (this.state.alerts.has(alert.id)) {
                alerts.set(alert.id, this.state.alerts.get(alert.id));
            } else {
                alerts.set(
                    alert.id,
                    <AlertCmp
                        {...{
                            bsStyle: alert.type,
                            id: 'alert' + alert.id,
                            key: 'alert' + alert.id,
                            closeLabel: alert.closeLabel || '',
                            lifeTime: this.props.lifeTime || DEFAULT_LIFE_TIME,
                            onDismiss: () => this.props.onDismiss?.(alert),
                        }}
                    >
                        {alert.content}
                    </AlertCmp>
                );
            }
        });

        this.setState({ alerts });
    }

    public render() {
        const alerts: AlertItem[] = [];
        this.state.alerts.forEach((v: AlertItem) => alerts.push(v));
        return (
            <div id="alerts-box" className="alerts-box">
                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/*@ts-ignore*/}
                {alerts}
            </div>
        );
    }
}

export default Alerts;
