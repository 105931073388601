import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AppURLKeysEnum } from 'ui-toolkit/constants/login.ts';
import { DEFAULT_LOGIN_URL } from '../constants/login.constants.ts';

export interface PasswordExpiredData {
    username: string;
    organization?: string;
    password: string;
}

export interface RedirectAppData {
    appKey: AppURLKeysEnum;
    appURL: string;
}

export interface LoginStore {
    isCaptchaRequired: boolean;
    isOrganizationRequired: boolean;
    appTitleKey: string;
    passwordExpired: PasswordExpiredData | null;
    redirectAppData: RedirectAppData;
    setPasswordExpired: (passwordExpired: PasswordExpiredData | null) => void;
    setOrganizationRequired: () => void;
    setCaptchaRequired: () => void;
    setRedirectAppData: (redirectAppData: RedirectAppData) => void;
    setAppTitle: (titleKey: string) => void;
}

export const useLoginStore = create<LoginStore>()(
    devtools(set => ({
        isCaptchaRequired: false,
        isOrganizationRequired: false,
        appTitleKey: 'auth.title.go',
        passwordExpired: null,
        redirectAppData: {
            appKey: AppURLKeysEnum.GO,
            appURL: DEFAULT_LOGIN_URL,
        },
        setPasswordExpired: (passwordExpired: PasswordExpiredData | null) =>
            set({ passwordExpired }),
        setOrganizationRequired: () => set({ isOrganizationRequired: true }),
        setCaptchaRequired: () => set({ isCaptchaRequired: true }),
        setRedirectAppData: (redirectAppData: RedirectAppData) =>
            set({ redirectAppData }),
        setAppTitle: (titleKey: string) => set({ appTitleKey: titleKey }),
    }))
);

export const { setRedirectAppData, setAppTitle } = useLoginStore.getState();
