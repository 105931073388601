import React from 'react';
import { toClasses } from '../../utils';

export interface Props extends React.HTMLProps<HTMLInputElement> {
    className?: string;
}

const Input = ({ ...props }: Props) => {
    const classes = React.useMemo(() => toClasses('control-input', props.className), [props.className]);

    return <input {...props} className={classes} />;
};

export default Input;
