import {
    availableLang,
    AvailableLang,
    LanguagesPaths,
} from '../constants/localization.constants.ts';
import i18n from './i18n.ts';
import { SingleLanguageTranslation } from '../../ui-toolkit/types/translations.ts';
import { api } from '../api/apiConfig.ts';

const translationTransform = (
    translationData: string
): SingleLanguageTranslation =>
    translationData
        .split('\n')
        .reduce((acc: Record<string, string>, current: string) => {
            if (current && current.trim().length > 0) {
                const parts = current.split('=');
                acc[parts[0]] = parts[1];
            }
            return acc;
        }, {});

const languages: LanguagesPaths = availableLang.reduce(
    (result, code) => ({
        ...result,
        [code]: `/messages.${code}`,
    }),
    {} as LanguagesPaths
);
export const fetchTranslations = (langs: AvailableLang[]): Promise<void[]> =>
    Promise.all(
        langs.map((name: AvailableLang) => {
            const path = languages[name];
            return api
                .get<string>('/translations' + path, {
                    baseURL: import.meta.env.BASE_URL,
                })
                .then((data: string) => {
                    i18n.addResourceBundle(
                        name,
                        'translation',
                        translationTransform(data)
                    );
                });
        })
    );
