import React, { useMemo } from 'react';

export interface Props {
    children: React.ReactNode;
    lineCount: number;
}

export const TextTruncate = ({ children, lineCount }: Props) => {
    const style = useMemo(() => ({ '--line-count': lineCount } as React.CSSProperties), [lineCount]);

    return (
        <span className={'text-truncate'} style={style}>
            {children}
        </span>
    );
};
