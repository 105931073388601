import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { LoginRoutes } from './routes/LoginRoutes.tsx';
import { useAppModel } from './useAppModel.ts';
import { AlertProvider } from 'ui-toolkit/context/Alert/AlertContext.tsx';
import Alerts from 'ui-toolkit/components/Alert/Alerts.tsx';
import Spinner from 'ui-toolkit/components/Spinner';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 60000,
        },
    },
});

const App = () => {
    const { alerts, onRemoveAlert, isLoading } = useAppModel();

    if (isLoading) return <Spinner className={'root-spinner'} />;

    return (
        <>
            <LoginRoutes />
            <Alerts alerts={alerts} onDismiss={onRemoveAlert} />
        </>
    );
};

const AppContainer = () => (
    <QueryClientProvider client={queryClient}>
        <AlertProvider>
            <App />
        </AlertProvider>
    </QueryClientProvider>
);

export default AppContainer;
