import { isNull, get, includes } from 'lodash-es';

export const isSearchInputClearBtn = (element: HTMLElement | null): boolean => {
    if (isNull(element)) return false;
    if (element.classList && element.classList.contains('search-input-clear-icon')) return true;
    return isSearchInputClearBtn(element.parentElement);
};

export const hasItemWithId = <T extends { id: string }>(value?: T) => (item: T) => item.id === get(value, 'id');

export const hasQuery = <T extends { search: string }>(query: string) => ({ search }: T) =>
    includes(search.toLowerCase(), query);
