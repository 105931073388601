import React from 'react';

interface Props {
    ownPosition: number;
    activePosition: number;
    previousPosition: number;
    direction?: 'forward' | 'backward';
    children: React.ReactNode;
}

function getDirection(activePosition: number, previousPosition: number): string {
    if (activePosition === previousPosition) {
        return '';
    }
    return activePosition > previousPosition ? 'forward' : 'backward';
}

const Step = ({ ownPosition, activePosition, previousPosition, children }: Props) => {
    const classes = React.useMemo(() => {
        const isActive = ownPosition === activePosition;
        const direction = getDirection(activePosition, previousPosition);

        return isActive ? `steps-form-move-${direction}` : 'hidden';
    }, [activePosition, previousPosition]);

    return <div className={classes}>{children}</div>;
};

export default Step;
