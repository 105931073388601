import React from 'react';

import { ENTER_KEY_CODE } from '../../../constants';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Translate } from '../../Translate/Translate';
import { TranslateFunction } from '../../../types/translations';
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
    login: string;
    organization: string;
    translate: TranslateFunction;
    isCaptchaRequired: boolean;
    isLoading: boolean;
    isActive: boolean;
    onBack: () => void;
    onForgotPassword: () => void;
    onEnterPress: (form: HTMLFormElement) => void;
}

const LoginFourthStep = ({
    login,
    organization,
    isCaptchaRequired,
    isLoading,
    isActive,
    translate,
    onBack,
    onEnterPress,
    onForgotPassword,
}: Props) => {
    const [captcha, setCaptcha] = React.useState('');

    const passwordRef = React.useRef<HTMLInputElement>(null);

    const handleBack = React.useCallback(() => onBack(), []);

    const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
        /* istanbul ignore else */
        if (event.keyCode === ENTER_KEY_CODE && (event.target as HTMLInputElement).form) {
            event.preventDefault();
            onEnterPress((event.target as HTMLInputElement).form as HTMLFormElement);
        }
    }, []);

    const handleCaptchaChanged = React.useCallback((response: string) => setCaptcha(response), []);
    const handleForgotPassword = React.useCallback(() => onForgotPassword(), []);

    React.useEffect(() => {
        /* istanbul ignore else */
        if (passwordRef.current && isActive) {
            passwordRef.current.focus();
        }
    }, [isActive]);

    return (
        <div className={'password-form'}>
            <input type="text" className="hidden" autoComplete="username" name="login" defaultValue={login} />
            <input type="text" className="hidden" name="organization" defaultValue={organization} />
            <input type="text" className="hidden" name="captcha" value={captcha} />
            <Input
                type="password"
                name="password"
                className="login-input"
                autoComplete="current-password"
                ref={passwordRef}
                onKeyDown={handleKeyDown}
                placeholder={translate('auth.password') as string}
            />
            {isCaptchaRequired && ( // @ts-ignore
                <ReCAPTCHA sitekey="6LdfaW0UAAAAAAPUo66GgwRS7Q8ZCl2nAQSpAGaL" onChange={handleCaptchaChanged} />
            )}
            <div className={'login-controls'}>
                <Button isOutline={true} className={'login-back-btn'} isRounded={true} onClick={handleBack}>
                    <Translate id={'common.back'} />
                </Button>
                <Button
                    color={'brand'}
                    className={'login-proceed-btn'}
                    isRounded={true}
                    type="submit"
                    isLoading={isLoading}
                    disabled={isCaptchaRequired && !captcha}
                >
                    <Translate id={'common.login'} />
                </Button>
            </div>
            <div className="text-center">
                <Button color={'link'} className={'login-link'} onClick={handleForgotPassword}>
                    <Translate id={'forgot.nav.main'} />
                </Button>
            </div>
        </div>
    );
};

export default LoginFourthStep;
