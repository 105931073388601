import { isUndefined } from 'lodash-es';

import { removeExtraSpaces } from '../../utils/stringHelpers';
import { Person, UserBase } from '../../types';

export const getInitialsFromPerson = (person: UserBase) => {
    let firstNameLetter = '';
    let lastNameLetter = '';

    if ((person as Person)?.firstName && (person as Person)?.lastName) {
        [firstNameLetter] = removeExtraSpaces((person as Person).firstName).toUpperCase();
        [lastNameLetter] = removeExtraSpaces((person as Person).lastName).toUpperCase();
    } else if (person.name) {
        [firstNameLetter = '', lastNameLetter = ''] = removeExtraSpaces(person.name)
            .split(' ')
            .map((i) => i[0].toUpperCase());
    }

    return `${firstNameLetter}${lastNameLetter}`;
};

export const getCachedAvatarImage = (() => {
    const cache: { [key: string]: string | null } = {};

    return (person: UserBase, personPromise: (personId: string) => Promise<string | null>): Promise<string | null> => {
        if (!isUndefined(cache[person.id])) return Promise.resolve(cache[person.id]);

        return personPromise(person.id)
            .then((image) => (cache[person.id] = image))
            .catch(() => (cache[person.id] = null))
            .finally(() => cache[person.id]);
    };
})();
