import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';

interface URLParams {
    username?: string;
    tenantId?: string;
}

export const NoSsoPage = () => {
    const { tenantId } = useParams<keyof URLParams>();
    const navigate = useNavigate();

    useEffect(() => {
        const processedTenantId =
            tenantId && tenantId?.endsWith('.')
                ? tenantId.substring(0, tenantId.length - 1)
                : tenantId;
        navigate(
            (processedTenantId ? `/login/${processedTenantId}` : '/') +
                location.search,
            {
                state: { ssoFailed: true },
            }
        );
    }, []);

    return null;
};
