import { useEffect, useRef } from 'react';

function usePrevious<P>(value: P) {
    const ref = useRef<P>(value);
    /* istanbul ignore next */
    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export default usePrevious;
