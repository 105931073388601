import React from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
    id: string;
}

export const Translate = ({ id }: Props) => {
    const { t } = useTranslation();
    return <>{t(id)}</>;
};
