/*eslint-disable no-magic-numbers*/
import React from 'react';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import { LoginFormTemplate } from '../LoginFormTemplate/LoginFormTemplate';
import Alert from '../../../components/Alert/Alert';
import { TranslateFunction } from '../../../types/translations';
import { Translate } from '../../Translate/Translate';
import { NavigateFunction } from '../../../types';

interface Props {
    logo: string;
    title?: string;
    prefix?: string;
    className?: string;
    tenantId?: string;
    translate: TranslateFunction;
    remindLoginPassword: (email: string) => Promise<unknown>;
    navigate: NavigateFunction;
}

interface State {
    email: string;
    error: string;
    isLoading: boolean;
    step: number;
}

export class RemindPasswordForm extends React.Component<Props & State> {
    public state = {
        email: '',
        error: '',
        isLoading: false,
        step: 1,
    };

    public static defaultProps = {
        title: 'auth.title',
        className: '',
        prefix: '',
    };

    public setError = (error: string) =>
        this.setState(
            {
                isLoading: false,
                error,
            },
            () => setTimeout(() => this.setState({ error: '' }), 2000)
        );

    public handleClearError = () => this.setState({ error: '' });

    private handleSubmit = (event: React.FormEvent<Element>) => event.preventDefault();

    private remindLoginPassword = () => {
        const { email } = this.state;
        const { remindLoginPassword } = this.props;

        this.setState(
            {
                isLoading: true,
                error: '',
            },
            () =>
                remindLoginPassword(email)
                    .then(() => this.setState({ isLoading: false, step: 2 }))
                    .catch(() => this.setError(this.getUnknownError()))
        );
    };

    private getUnknownError() {
        return this.props.translate('common.unknownError') as string;
    }

    private updateEmail = (email: string) => this.setState({ email });

    public renderCurrentStep() {
        const { translate } = this.props;
        const { step, email, isLoading } = this.state;
        switch (step) {
            case 1:
                return (
                    <FirstStep
                        {...{ email, isLoading, translate }}
                        onChange={this.updateEmail}
                        next={this.remindLoginPassword}
                    />
                );
            // TODO does this  part needed ???
            case 2:
                return <SecondStep {...{ email, translate }} />;
            default:
                return null;
        }
    }

    public handleReturnHome() {
        this.props.navigate(
            this.props.tenantId ? `${this.props.prefix}/login/${this.props.tenantId}` : `${this.props.prefix}/`
        );
    }

    public render() {
        const { translate, title, prefix, logo } = this.props;
        const { error, step } = this.state;
        return (
            <LoginFormTemplate
                logo={logo}
                title={translate(title as string) as string}
                subTitle={step === 1 ? (translate('forgot.remind.title') as string) : ''}
            >
                <form className="remind-form" onSubmit={this.handleSubmit}>
                    {this.renderCurrentStep()}
                    {!!error && (
                        <Alert className={'remind-form-error-alert'} bsStyle="danger" onDismiss={this.handleClearError}>
                            {error}
                        </Alert>
                    )}
                    <div className={'login-links'}>
                        {/*<Link to={`${prefix}/`}>*/}
                        <Button className={'login-link'} color={'link'} onClick={() => this.handleReturnHome()}>
                            <Translate id={'forgot.nav.home'} />
                        </Button>
                        {/*</Link>*/}
                        <Link to={`${prefix}/reset`}>
                            <Button className={'login-link'} color={'link'}>
                                <Translate id={'forgot.nav.codeVerification'} />
                            </Button>
                        </Link>
                    </div>
                </form>
            </LoginFormTemplate>
        );
    }
}
