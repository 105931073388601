import * as React from 'react';
import Spinner from '../../Spinner';

interface Props {
    title: string;
    subTitle?: string;
    logo?: string;
    children: React.ReactNode;
    className?: string;
    isLoading?: boolean;
}

export const LoginFormTemplate = ({
    title,
    subTitle = '',
    logo = `/spacewell-logo-full-size.svg`,
    children,
    className = '',
    isLoading = false,
}: Props) => (
    <section className={`login-container ${className}`}>
        <div className={'form-wrapper'}>
            <img className="login-logo" alt="logo" src={logo} />
            <h2 className="login-title">{title}</h2>
            {subTitle && <h4 className="login-subtitle">{subTitle}</h4>}
            <div className={`login-content-wrapper`}>
                {children}
                {isLoading && (
                    <div className={'spinner-wrap'}>
                        <Spinner />
                    </div>
                )}
            </div>
            <p className="login-copyright">&#169; Workplace {new Date().getFullYear()}. All Rights Reserved</p>
        </div>
    </section>
);
