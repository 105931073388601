import React from 'react';
import { AppIcon } from '../Icon/AppIcon/AppIcon';

interface Props {
    children: React.ReactNode;
    className?: string;
    onDismiss?: () => void;
    showCloseButton?: boolean;
}

export const AppAlert = ({ children, className = '', onDismiss, showCloseButton = true }: Props) => (
    <div className={`app-alert ${className}`}>
        <div className={'alert-content'}>{children}</div>
        {showCloseButton && (
            <button onClick={onDismiss} className={'close-btn'}>
                <AppIcon name="close" />
            </button>
        )}
    </div>
);
