/* eslint-disable no-magic-numbers */
import { isString } from 'lodash-es';

// TODO remake these translations
export function formatString(text: string, ...args: Array<string | number>): string {
    const params = Array.prototype.slice.call(args, 0);
    return text.replace(/{(\d+)}/g, (match, nbr) => (typeof params[nbr] !== 'undefined' ? params[nbr] : match));
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function toClasses(...args: any[]): string {
    return args.filter((item) => !!item && isString(item)).join(' ');
}

export function generateId() {
    return `local-${(~~(Math.random() * 1e8)).toString(16)}`;
}

export function isScrollable(element?: HTMLElement | null): boolean {
    if (element) {
        return element.scrollHeight > element.clientHeight;
    }
    return false;
}

export const scrollToTop = (element: HTMLElement | null, offset = 0) => {
    if (!element || !element.parentNode) return;
    (element.parentNode as HTMLElement).scrollTop = element.offsetTop + offset;
};

export function inCurrent(current: HTMLElement | null, element: HTMLElement | null): boolean {
    if (current === null || element === null || element.tagName.toUpperCase() === 'BODY') {
        return false;
    }
    if (current === element) {
        return true;
    }
    return inCurrent(current, element.parentElement);
}

export const isEmail = (str: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(str);
};
