import React from 'react';
import { isBoolean } from 'lodash-es';

import { toClasses } from '../../utils';
import { AppIcon } from '../Icon/AppIcon/AppIcon';

export type ButtonStyleType =
    | 'brand'
    | 'success'
    | 'warning'
    | 'danger'
    | 'default'
    | 'icon'
    | 'transparent'
    | 'link'
    | 'light-brand'
    | 'no-fill'
    | 'shadow';
export type IconPlacement = 'START' | 'END';
export type ButtonSize = 'small' | 'medium' | 'large';

interface Props extends React.HTMLProps<HTMLButtonElement> {
    children: React.ReactNode;
    color?: ButtonStyleType;
    type?: 'button' | 'submit' | 'reset';
    isLoading?: boolean;
    isOutline?: boolean;
    isRounded?: boolean;
    fullWidth?: boolean;
    iconName?: string;
    iconPlacement?: IconPlacement;
    buttonSize?: ButtonSize;
}

export const Button = ({
    children,
    color = 'default',
    type = 'button',
    isOutline = false,
    isRounded = false,
    isLoading,
    fullWidth,
    iconName = '',
    iconPlacement = 'END',
    buttonSize = 'medium',
    ...props
}: Props) => {
    const classes = React.useMemo(
        () =>
            toClasses(
                'app-btn',
                isOutline ? `app-btn-outline-${color}` : `app-btn-${color}`,
                isRounded && `app-btn-rounded`,
                fullWidth && `app-btn-full-width`,
                isBoolean(isLoading) && `app-btn-loading`,
                !!iconName && `app-btn-with-icon`,
                !!iconName && iconPlacement === 'START' ? 'icon-start' : '',
                buttonSize !== 'medium' && buttonSize,
                props.className
            ),
        [color, isLoading, isOutline, isRounded, props.className, iconName, iconPlacement, buttonSize, fullWidth]
    );

    return (
        <button {...props} type={type} disabled={isLoading || props.disabled} className={classes}>
            {children}
            {isBoolean(isLoading) && isLoading && <span className="app-btn-spinner" />}
            {!!iconName && <AppIcon name={iconName} />}
        </button>
    );
};

export default Button;
