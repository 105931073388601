import {
    ChangePasswordResponse,
    LastLogin,
    LoginRequestBody,
    LoginResponse,
    PasswordRules,
} from '../type';
import { api } from './apiConfig.ts';
import { ChangePasswordData, SSOSettings } from '../../ui-toolkit/types';
import { interpURLs } from './http.utils.ts';
import { ApiVersionsEnum } from 'ui-toolkit/constants/login.ts';

export const urls = interpURLs({
    login: '/auth/login',
    tenantSsoSettings: '/auth/ssosettingsbyorg/:tenantId',
    ssoSettings: '/auth/ssosettings/:id',
    resetPassword: '/auth/resetpassword',
    passwordRules: '/auth/settings/password?org=:orgId&loginId=:loginId',
    remindPassword: '/auth/remindpassword',
    changePassword: '/auth/changepassword/unauthenticated',
    lastLogins: '/auth/lastlogins',
    addLastLogin: '/auth/lastlogins/user',
    removeLastLogin: '/auth/lastlogins/user/:userId',
    getUserThumbnail: '/org/:organization/users/:id/thumbnail',
    setUserImage: '/auth/user/thumbnail',
});

export const getSsoSettingsForTenant = (
    tenantId: string
): Promise<SSOSettings> =>
    api.get(urls.tenantSsoSettings({ tenantId }, ApiVersionsEnum.v2));

export const getSsoSettings = (
    id: string,
    organization?: string,
    baseUrl?: string
): Promise<SSOSettings> =>
    api.get(urls.ssoSettings({ id }, ApiVersionsEnum.v2), {
        params: {
            organization,
        },
        baseURL: baseUrl,
    });

export const login = (
    id: string,
    password: string,
    captcha = '',
    organization = ''
): Promise<LoginResponse> =>
    api.post<LoginRequestBody, LoginResponse>(
        urls.login(null, ApiVersionsEnum.v2),
        {
            password,
            captcha,
            organization,
            setCookie: true,
            rememberme: true,
            username: id,
        }
    );

export const resetPassword = (code: string, password: string) =>
    api.post(urls.resetPassword(null, ApiVersionsEnum.v2), {
        code,
        password,
    });

export const getPasswordRules = (organizationId: string, userId: string) =>
    api.get<PasswordRules>(
        urls.passwordRules(
            { orgId: organizationId, loginId: userId },
            ApiVersionsEnum.v2
        )
    );

export const checkResetToken = (code: string) =>
    api.post(urls.resetPassword(null, ApiVersionsEnum.v2), { code });

export const remindLoginPassword = (loginId: string,  url: string) =>
    api.post(urls.remindPassword(null, ApiVersionsEnum.v2), { loginId, url });

export const changePassword = (
    data: ChangePasswordData
): Promise<ChangePasswordResponse> =>
    api.post<ChangePasswordData, ChangePasswordResponse>(
        urls.changePassword(null, ApiVersionsEnum.v2),
        {
            ...data,
        }
    );

export const getAllLastLogins = () =>
    api.get<LastLogin[]>(urls.lastLogins(undefined, ApiVersionsEnum.v2));

export const setLastLogin = () =>
    api.put(urls.addLastLogin(undefined, ApiVersionsEnum.v2), {});

export const removeLastLogin = (userId: string) =>
    api.delete(urls.removeLastLogin({ userId }, ApiVersionsEnum.v2));

export const setImageForClient = (params = { size: 650 }) =>
    api.get(urls.setUserImage(undefined, ApiVersionsEnum.v2), { params });

export const getUserThumbnail = (
    tenant: string,
    userId: string,
    params = { size: 650 }
) =>
    api.get<Blob>(
        urls.getUserThumbnail(
            { organization: tenant, id: userId },
            ApiVersionsEnum.v2
        ),
        {
            params,
            responseType: 'blob',
        }
    );
