import React, { createContext, useCallback, useState } from 'react';
import { isString, noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { generateId } from '../../utils';
import { ErrorResponse } from '../../types/http';
import { AlertItem } from '../../types/alert';
import { AlertContextProps, AlertToAdd } from './AlertContext.types';

interface Props {
    children: React.ReactNode;
}

export const AlertContext = createContext<AlertContextProps>({
    alerts: [],
    onAddAlert: noop,
    onRemoveAlert: noop,
    onErrorAlert: noop,
});

export const AlertProvider = ({ children }: Props) => {
    const { t } = useTranslation();
    const [alerts, setAlerts] = useState<AlertItem[]>([]);

    const onAddAlert = useCallback(
        (alert: AlertToAdd) => setAlerts((prevAlerts) => [...prevAlerts, { id: generateId(), ...alert }]),
        []
    );

    const onRemoveAlert = useCallback(
        (alert: AlertItem) => setAlerts((prevAlerts) => prevAlerts.filter((prevAlert) => prevAlert.id !== alert.id)),
        []
    );

    const onErrorAlert = useCallback((error: Error | ErrorResponse | string) => {
        let content;

        if (isString(error) && error) content = error;
        else if ((error as ErrorResponse)[1]) content = (error as ErrorResponse)[1]?.data.message;
        else if (error as Error) content = (error as Error).message;

        onAddAlert({ type: 'danger', content: content || t('errors.somethingWrong') });
    }, []);

    return (
        <AlertContext.Provider value={{ alerts, onAddAlert, onRemoveAlert, onErrorAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
