import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './styles/index.scss';
import * as React from 'react';
import {
    AppURLKeysEnum,
    LOGIN_QUERY_APP_KEY,
    LOGIN_QUERY_APP_URL,
} from 'ui-toolkit/constants/login.ts';
import { setRedirectAppData } from './store/login.store.ts';
import { DEFAULT_LOGIN_URL } from './constants/login.constants.ts';

const queryString = window.location.search;
const searchParams = new URLSearchParams(queryString);
const appKey = searchParams.get(LOGIN_QUERY_APP_KEY);
const appUrl = searchParams.get(LOGIN_QUERY_APP_URL);

if (appKey === AppURLKeysEnum.RoomFinder) {
    setRedirectAppData({
        appKey: appKey,
        appURL: appUrl || DEFAULT_LOGIN_URL,
    });
    Office.onReady(() => {
        createRoot(document.getElementById('root')!).render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
    });
} else {
    createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}
