import { union } from 'lodash-es';
import { LOGIN_CACHED_TENANTS_LS_KEY } from '../constants/login';

export const saveLoginTenantToLs = (email: string, tenantId: string) => {
    const organizations: string[] = JSON.parse(localStorage.getItem(LOGIN_CACHED_TENANTS_LS_KEY + email) || '[]');
    localStorage.setItem(LOGIN_CACHED_TENANTS_LS_KEY + email, JSON.stringify(union([tenantId], organizations)));
};

export const getCachedLoginTenants = (email: string): string[] =>
    JSON.parse(localStorage.getItem(LOGIN_CACHED_TENANTS_LS_KEY + email) || '[]');
