import React from 'react';
import { AppIcon } from '../../Icon/AppIcon/AppIcon';

interface Props {
    children: React.ReactNode | React.ReactNode[];
    onDismiss: () => void;
}

export const ErrorContainer = ({ children, onDismiss }: Props) => (
    <div className={`app-error-container`}>
        <button onClick={onDismiss} className={'close-btn'}>
            <AppIcon name="close" />
        </button>
        <div className={'error-content'}>{children}</div>
    </div>
);
