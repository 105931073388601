import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CenteredImage } from '../CenteredImage/CenteredImage';
import { stringToHash } from '../../utils/cryptography.utils';
import { toClasses } from '../../utils';
import { getCachedAvatarImage, getInitialsFromPerson } from './Avatar.utils';
import { UserBase } from '../../types';

const INITIAL_STYLE_CLASS_SET = ['style-1', 'style-2', 'style-3', 'style-4', 'style-5', 'style-6', 'style-7'];

export interface Props {
    person: UserBase | null;
    readyForLoading?: boolean;
    onAvatarReady?: () => void;
    styles?: React.CSSProperties;
    showInitials?: boolean;
    className?: string;
    image?: string;
    placeholderPromise: () => Promise<string | null>;
    personPromise: (personId: string) => Promise<string | null>;
    onClick?: () => void;
}

export const Avatar = ({
    person,
    readyForLoading = true,
    onAvatarReady,
    styles,
    showInitials,
    className,
    image,
    placeholderPromise,
    personPromise,
    onClick,
}: Props) => {
    const [imageInBase64, setImageInBase64] = useState<string | null>(image || null);
    const [isLoaded, setIsLoaded] = useState(!!image);
    const [showPlaceholder, setShowPlaceholder] = useState(false);

    const handleCachedImage = useCallback(
        (cachedImage: string | null) => {
            if (!cachedImage && (!showInitials || !person)) {
                return placeholderPromise()
                    .then(setImageInBase64)
                    .then(() => setShowPlaceholder(true));
            }

            return setImageInBase64(cachedImage);
        },
        [showInitials, placeholderPromise, person]
    );

    const onReady = useCallback(() => {
        setIsLoaded(true);
        onAvatarReady?.();
    }, [onAvatarReady]);

    const initials = useMemo(() => (person ? getInitialsFromPerson(person) : ''), [person]);

    const initialStyleClass = useMemo(() => {
        const hash = stringToHash(person?.name || '');
        const stylesIndex = Math.abs(hash) % INITIAL_STYLE_CLASS_SET.length;
        return INITIAL_STYLE_CLASS_SET[stylesIndex];
    }, [person?.name]);

    const classes = useMemo(() => toClasses('avatar-container', showPlaceholder && 'with-placeholder', className), [
        showPlaceholder,
        className,
    ]);

    useEffect(() => {
        if (!isLoaded && readyForLoading && !imageInBase64) {
            if (person) {
                getCachedAvatarImage(person, personPromise).then(handleCachedImage).finally(onReady);
            } else {
                handleCachedImage(null);
                onReady();
            }
        }
    }, [isLoaded, readyForLoading, imageInBase64, person, personPromise, handleCachedImage, onReady]);

    return (
        <div className={classes} style={styles} onClick={onClick}>
            {imageInBase64 && <CenteredImage className="avatar" src={imageInBase64} alt={person?.name} />}
            {showInitials && !imageInBase64 && (
                <div className={toClasses('avatar initials-only', initialStyleClass)}>{initials}</div>
            )}
        </div>
    );
};
