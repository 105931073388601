import React from 'react';

import SnowmanMenu from '../../../components/Snowman/Snowman';
import SnowmanMenuItem from '../../../components/Snowman/SnowmanItem';
import { Translate } from '../../Translate/Translate';

interface UserAction {
    label: string;
    handler: () => void;
}

interface Props {
    actions: UserAction[];
}

const UserCardActions = ({ actions }: Props) => {
    if (actions.length) {
        return (
            <div className="user-card__actions">
                <SnowmanMenu>
                    {actions.map(({ label, handler }) => (
                        <SnowmanMenuItem key={label} onClick={handler}>
                            <Translate id={label} />
                        </SnowmanMenuItem>
                    ))}
                </SnowmanMenu>
            </div>
        );
    }
    return null;
};

export default UserCardActions;
