import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
    changePassword,
    login,
    setImageForClient,
} from '../../api/login.api.ts';
import {
    ChangePasswordResponse,
    LoginErrorData,
    LoginResponse,
} from '../../type';
import { loginErrors } from '../../constants/login.constants.ts';
import { LoginStore, useLoginStore } from '../../store/login.store.ts';
import { ChangePasswordData } from '../../../ui-toolkit/types';
import { AlertContext } from 'ui-toolkit/context/Alert/AlertContext.tsx';
import { ErrorResponse } from 'ui-toolkit/types/http.ts';
import { AppURLKeysEnum } from 'ui-toolkit/constants/login.ts';
import { LastLoginsModel, useLastLoginsModel } from './ useLastLoginsModel.ts';
import { saveLoginTenantToLs } from 'ui-toolkit/utils/login.ts';

interface UseLoginModelState {
    onLogIn: (
        username: string,
        password: string,
        captcha: string,
        organization: string,
        saveLoginToLs?: boolean
    ) => Promise<boolean>;
    isLoading: boolean;
    isLastLoginsFetched: boolean;
    onChangePassword: (data: ChangePasswordData) => Promise<unknown>;
}

interface LoginArgs {
    username: string;
    password: string;
    captcha: string;
    organization?: string;
}

const handleLoginError = (
    error: LoginErrorData,
    { organization, password, username, captcha }: LoginArgs,
    loginStore: LoginStore,
    onErrorAlert: (error: Error | ErrorResponse | string) => void
) => {
    let message = error.message;

    switch (error.code) {
        case loginErrors.PASSWORD_EXPIRED:
            loginStore.setPasswordExpired({ organization, username, password });
            message = !captcha ? '' : message;
            break;
        case loginErrors.INVALID_CAPTCHA:
            loginStore.setCaptchaRequired();
            break;
        case loginErrors.ORGANIZATION_REQUIRED:
            loginStore.setOrganizationRequired();
            break;
    }

    if (message) onErrorAlert(message);
};

export const useLoginModel = (): UseLoginModelState &
    Omit<LastLoginsModel, 'isFetched'> => {
    const { isFetched, ...lastLoginsModel } = useLastLoginsModel();
    const { onErrorAlert } = useContext(AlertContext);
    const loginStore = useLoginStore(store => store);
    const [isRedirecting, setIsRedirecting] = useState(false);

    const { mutateAsync: doLogin, isPending } = useMutation<
        LoginResponse,
        LoginErrorData,
        LoginArgs
    >({
        mutationFn: ({ username, password, captcha, organization }) =>
            login(username, password, captcha, organization),
        onError: (error: LoginErrorData, variables: LoginArgs) =>
            handleLoginError(error, variables, loginStore, onErrorAlert),
    });

    const { mutateAsync: doChangePassword } = useMutation<
        ChangePasswordResponse,
        AxiosError,
        ChangePasswordData
    >({
        mutationFn: data => changePassword(data),
    });

    const handleLogIn = useCallback(
        (
            username: string,
            password: string,
            captcha: string,
            organization: string,
            saveLoginToLs?: boolean
        ): Promise<boolean> =>
            doLogin({
                username,
                password,
                captcha,
                organization,
            })
                .then(() => {
                    // this save list of last selected tenant into storage for multiselect screen
                    if (saveLoginToLs)
                        saveLoginTenantToLs(username, organization);
                    setIsRedirecting(true);
                    return Promise.allSettled([
                        lastLoginsModel.handleSetLastLogin(),
                        setImageForClient(),
                    ]);
                })
                .then(() => {
                    //TODO remove when old studio will be gone
                    if (
                        loginStore.redirectAppData.appKey ===
                        AppURLKeysEnum.Studio
                    ) {
                        const studioUrl = new window.URL(
                            loginStore.redirectAppData.appURL
                        );
                        if (studioUrl.pathname.startsWith('/no-sso')) {
                            studioUrl.pathname = '/beta';
                            location.href = studioUrl.toString();
                            return Promise.resolve(true);
                        }
                    }

                    // here we can JSON.stringify any object and send data to outlook app
                    loginStore.redirectAppData.appKey ===
                        AppURLKeysEnum.RoomFinder &&
                        Office.context.ui.messageParent('login_success');

                    location.href = loginStore.redirectAppData.appURL;
                    return Promise.resolve(true);
                }),
        [doLogin, loginStore.redirectAppData]
    );

    const handleChangePassword = useCallback(
        (data: ChangePasswordData) =>
            doChangePassword(data).then(() =>
                handleLogIn(
                    data.username,
                    data.newPassword,
                    data.captcha,
                    data.organization as string
                )
            ),
        [doChangePassword, doLogin, handleLogIn]
    );

    return {
        ...lastLoginsModel,
        onLogIn: handleLogIn,
        isLastLoginsFetched: isFetched,
        isLoading: isPending || isRedirecting,
        onChangePassword: handleChangePassword,
    };
};
