import Spinner from 'ui-toolkit/components/Spinner';
import { useEffect } from 'react';
import { LOGIN_QUERY_APP_URL } from 'ui-toolkit/constants/login.ts';
import { DEFAULT_LOGIN_URL } from '../../constants/login.constants.ts';
import { setImageForClient, setLastLogin } from '../../api/login.api.ts';

export const SuccessSso = () => {
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        Promise.allSettled([setLastLogin(), setImageForClient()]).finally(
            () => {
                window.location.href = decodeURIComponent(
                    searchParams.get(LOGIN_QUERY_APP_URL) || DEFAULT_LOGIN_URL
                );
            }
        );
    }, []);

    return <Spinner />;
};
