import { useLocation } from 'react-router-dom';

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ssoImagePath } from '../../constants/localization.constants.ts';
import { SsoFailed } from 'ui-toolkit/components/Login/NoSSO/SsoFailed.tsx';
import { ErrorBoundary } from 'ui-toolkit/components/ErrorBoundary/ErrorBoundary.tsx';

interface URLParams {
    username?: string;
    tenantId?: string;
}

export const SsoFailedPage = () => {
    const location = useLocation();
    const [tenantId, setTenantId] = useState<string>();
    const { username } = useParams<keyof URLParams>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleTryAgain = useCallback(() => {
        const queryState = new URLSearchParams(location.search);

        if (tenantId)
            navigate(
                (tenantId ? `/login/${tenantId}` : '/') +
                    `?${queryState.toString()}`
            );
        else {
            queryState.set('org', '');
            window.location.href = `/saml/init/${encodeURIComponent(username!)}/?${queryState.toString()}`;
        }
    }, [username, tenantId, navigate, location.search]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setTenantId(queryParams.get('org') || undefined);
    }, [location.search]);

    return (
        <ErrorBoundary>
            <SsoFailed
                translate={t}
                logo={ssoImagePath}
                onTryAgain={handleTryAgain}
            />
        </ErrorBoundary>
    );
};
