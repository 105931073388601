import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_URL, VITE_BASE_URL } from '../constants';

export interface RequestConfig extends AxiosRequestConfig {}
const apiUrl = API_URL || '/';
const viteBaseUrl = VITE_BASE_URL || '/';

const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 120000,
});

axiosInstance.defaults.withCredentials = true;

export const api = {
    getInstance: () => axiosInstance,
    setBaseURL: (newBaseUrl: string) => {
        axiosInstance.defaults.baseURL = newBaseUrl;
    },
    getBaseURL: () => axiosInstance.defaults.baseURL,
    get: <T>(url: string, config?: RequestConfig) =>
        axiosInstance
            .get<T>(url, config)
            .then(resp => resp.data as T)
            .catch((e: AxiosError) => Promise.reject(e.response?.data)),
    getWithResponse: <T>(url: string, config?: RequestConfig) =>
        axiosInstance.get<T>(url, config).then(resp => resp),
    post: <T, R = undefined>(url: string, body: T, config?: RequestConfig) =>
        axiosInstance
            .post<R>(url, body, config)
            .then(resp => resp.data as R)
            .catch((e: AxiosError) => Promise.reject(e.response?.data)),
    put: <T>(url: string, body: T, config?: RequestConfig) =>
        axiosInstance
            .put<T>(url, body, config)
            .then(resp => resp.data as T)
            .catch((e: AxiosError) => Promise.reject(e.response?.data)),
    delete: <T = undefined>(url: string, config?: RequestConfig) =>
        axiosInstance
            .delete<T>(url, config)
            .then(resp => resp.data as T)
            .catch((e: AxiosError) => Promise.reject(e.response?.data)),
};

export const setBaseURL = (url: string) => `${viteBaseUrl}${url}`;
