import { AppURLKeysEnum } from 'ui-toolkit/constants/login.ts';

export const loginErrors = {
    ORGANIZATION_REQUIRED: 'ORGANIZATION_REQUIRED',
    ACCOUNT_DISABLED: 'ACCOUNT_DISABLED',
    PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
    INVALID_LOGIN_OR_PASSWORD: 'INVALID_LOGIN_OR_PASSWORD',
    INVALID_CAPTCHA: 'INVALID_CAPTCHA',
};

export const DEFAULT_LOGIN_URL = 'https://go.cobundu.com';

export const AppTitleKeyMap = {
    [AppURLKeysEnum.GO]: 'auth.title.go',
    [AppURLKeysEnum.Studio]: 'auth.title.studio',
    [AppURLKeysEnum.RoomFinder]: 'auth.title.roomFinder',
    [AppURLKeysEnum.SpaceManagement]: 'auth.title.space',
};
