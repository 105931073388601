import React from 'react';

import { ENTER_KEY_CODE } from '../../../constants';
import Input from '../../../components/Input';
import { LoginFormTemplate } from '../LoginFormTemplate/LoginFormTemplate';
import Button from '../../../components/Button';
import { Translate } from '../../Translate/Translate';
import { TranslateFunction } from '../../../types/translations';

interface Props {
    title?: string;
    subtitle?: string;
    className?: string;
    logo: string;
    translate: TranslateFunction;
    onLogin: (username: string) => void;
    onForgotPassword: () => void;
}

const LoginOnlySSO = ({
    translate,
    title = 'auth.title',
    logo,
    subtitle = 'auth.subTitle',
    onLogin,
    onForgotPassword,
}: Props) => {
    const [login, setLogin] = React.useState('');

    const handleLoginChange = React.useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(target.value + '');
    }, []);
    const handleKeyDown = React.useCallback(
        ({ keyCode }: React.KeyboardEvent<HTMLInputElement>) => {
            if (keyCode === ENTER_KEY_CODE) {
                onLogin(login);
            }
        },
        [login]
    );
    const handleLogin = React.useCallback(() => onLogin(login), [login]);
    const handleForgotPassword = React.useCallback(() => onForgotPassword(), [login]);

    return (
        <LoginFormTemplate logo={logo} title={translate(title) as string} subTitle={translate(subtitle) as string}>
            <Input
                type="text"
                className={'login-input'}
                onKeyDown={handleKeyDown}
                onChange={handleLoginChange}
                placeholder={translate('auth.usernameOrEmail') as string}
            />
            <Button
                color="brand"
                className={'login-proceed-btn'}
                isRounded={true}
                disabled={!login.length}
                isLoading={false}
                onClick={handleLogin}
            >
                <Translate id={'common.next'} />
            </Button>
            <div className="text-center">
                <Button color="link" onClick={handleForgotPassword}>
                    <Translate id={'forgot.nav.main'} />
                </Button>
            </div>
        </LoginFormTemplate>
    );
};

LoginOnlySSO.defaultProps = {
    title: 'auth.title',
    subtitle: 'auth.subTitle',
};

export default LoginOnlySSO;
